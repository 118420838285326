import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';

// Pages
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import ApplicantForm from './pages/ApplicantForm';
import Assessment from './pages/Assessment';
import PreliminaryAssessment from './pages/PreliminaryAssessment';
import QualificationDecision from './pages/QualificationDecision';
import ComplianceAssessment from './pages/ComplianceAssessment';
import FinalReport from './pages/FinalReport';
import Results from './pages/Results';
import CertificationDetails from './pages/CertificationDetails';
import NotFound from './pages/NotFound';

// New Assessment Flow Pages
import RegistrationScreen from './pages/RegistrationScreen';
import AuditQuestionsScreen from './pages/AuditQuestionsScreen';
import AssessmentSummary from './pages/AssessmentSummary';

// Extended Assessment Pages
import ExtendedAssessmentAccess from './pages/extendedAssessment/ExtendedAssessmentAccess';
import ExtendedAssessmentForm from './pages/extendedAssessment/ExtendedAssessmentForm';
import ExtendedAssessmentResults from './pages/extendedAssessment/ExtendedAssessmentResults';

// Layout components
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow container mx-auto px-4 py-8">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route 
                path="/applicant-form" 
                element={
                  <PrivateRoute>
                    <ApplicantForm />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/assessment" 
                element={
                  <PrivateRoute>
                    <Assessment />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/preliminary-assessment" 
                element={<PreliminaryAssessment />} 
              />
              <Route 
                path="/qualification-decision" 
                element={<QualificationDecision />} 
              />
              <Route 
                path="/compliance-assessment" 
                element={<ComplianceAssessment />} 
              />
              <Route 
                path="/final-report" 
                element={<FinalReport />} 
              />
              <Route 
                path="/results" 
                element={
                  <PrivateRoute>
                    <Results />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/certification-details" 
                element={
                  <PrivateRoute>
                    <CertificationDetails />
                  </PrivateRoute>
                } 
              />
              {/* New Assessment Flow Routes */}
              <Route path="/registration" element={<RegistrationScreen />} />
              <Route path="/audit-questions" element={<AuditQuestionsScreen />} />
              <Route path="/assessment-summary" element={<AssessmentSummary />} />
              
              {/* Extended Assessment Routes */}
              <Route 
                path="/extended-assessment-access" 
                element={
                  <PrivateRoute>
                    <ExtendedAssessmentAccess />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/extended-assessment" 
                element={
                  <PrivateRoute>
                    <ExtendedAssessmentForm />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/extended-assessment-results" 
                element={
                  <PrivateRoute>
                    <ExtendedAssessmentResults />
                  </PrivateRoute>
                } 
              />
              
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
