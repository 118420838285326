import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';

// Validation schema
const RegistrationSchema = Yup.object().shape({
  // Organization Information
  organisation_name: Yup.string().required('Organization name is required'),
  organisation_address: Yup.string().required('Organization address is required'),
  industry_sector: Yup.string().required('Industry sector is required'),
  
  // User Information
  user_name: Yup.string().required('User name is required'),
  user_email: Yup.string().email('Invalid email format').required('User email is required'),
  
  // Terms acceptance
  terms_accepted: Yup.boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions')
});

interface RegistrationFormData {
  organisation_name: string;
  organisation_address: string;
  industry_sector: string;
  user_name: string;
  user_email: string;
  terms_accepted: boolean;
}

const RegistrationScreen: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  // Industry sectors options
  const industrySectors = [
    'Financial Services',
    'Healthcare',
    'Technology',
    'Education',
    'Retail',
    'Manufacturing',
    'Government',
    'Legal Services',
    'Transportation',
    'Energy',
    'Other',
  ];

  const initialValues: RegistrationFormData = {
    organisation_name: '',
    organisation_address: '',
    industry_sector: '',
    user_name: '',
    user_email: '',
    terms_accepted: false
  };

  const handleSubmit = async (values: RegistrationFormData, { setSubmitting }: any) => {
    try {
      // Store registration data in localStorage
      localStorage.setItem('registration_data', JSON.stringify(values));
      
      // Navigate to the preliminary assessment
      navigate('/preliminary-assessment');
    } catch (err: any) {
      setError(err.message || 'An error occurred during registration');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto relative">
      {/* Decorative elements */}
      <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Registration
        </h1>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
            {error}
          </div>
        )}

        <div className="bg-white rounded-lg shadow-lg p-8">
          <Formik
            initialValues={initialValues}
            validationSchema={RegistrationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-8">
                {/* Organization Information Section */}
                <div className="border-b border-gray-200 pb-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Organization Information
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="organisation_name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Organization Name *
                      </label>
                      <Field
                        type="text"
                        name="organisation_name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your organization name"
                      />
                      <ErrorMessage
                        name="organisation_name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="industry_sector"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Industry Sector *
                      </label>
                      <Field
                        as="select"
                        name="industry_sector"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select Industry Sector</option>
                        {industrySectors.map((sector) => (
                          <option key={sector} value={sector}>
                            {sector}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="industry_sector"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label
                        htmlFor="organisation_address"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Organization Address *
                      </label>
                      <Field
                        as="textarea"
                        name="organisation_address"
                        rows={3}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your organization address"
                      />
                      <ErrorMessage
                        name="organisation_address"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>

                {/* User Information Section */}
                <div className="border-b border-gray-200 pb-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    User Information
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="user_name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        User Name *
                      </label>
                      <Field
                        type="text"
                        name="user_name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your full name"
                      />
                      <ErrorMessage
                        name="user_name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="user_email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        User Email *
                      </label>
                      <Field
                        type="email"
                        name="user_email"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your email address"
                      />
                      <ErrorMessage
                        name="user_email"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>

                {/* Terms and Conditions */}
                <div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <Field
                        type="checkbox"
                        name="terms_accepted"
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="terms_accepted" className="font-medium text-gray-700">
                        I have read and accept the Privacy Policy and Terms of Service *
                      </label>
                      <ErrorMessage
                        name="terms_accepted"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 disabled:opacity-50"
                  >
                    {isSubmitting ? 'Submitting...' : 'Continue to Assessment'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegistrationScreen;
