import axios from 'axios';

// API endpoints - corrected to match actual PHP files
const QUESTIONS_URL = '/api/assessment-questions.php';
const RESPONSES_URL = '/api/assessment.php'; // Changed from assessment-responses.php to assessment.php
const RESULTS_URL = '/api/assessment-results.php';
const SESSION_MANAGER_URL = '/api/session-manager.php'; // New endpoint for session management

// Get token from local storage
const getToken = (): string | null => {
  const user = localStorage.getItem('user');
  if (user) {
    try {
      const userData = JSON.parse(user);
      return userData.token;
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  }
  return null;
};

// Configure axios with auth header
const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // Also set token as cookie as fallback
      document.cookie = `token=${token}; path=/; secure; samesite=strict`;
    }
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Types for the assessment questionnaire
export interface QuestionOption {
  option_id: string;
  option_value: string;
  option_text: string;
}

export interface AssessmentQuestion {
  question_id: string;
  text: string;
  type: 'yes_no' | 'text' | 'number' | 'dropdown' | 'multiple_choice';
  order_num: number;
  is_required: boolean;
  parent_question_id?: string;
  parent_answer?: string;
  guidance_text?: string;
  options?: QuestionOption[];
  children?: AssessmentQuestion[];
}

export interface QuestionResponse {
  questionId: string;
  value: string | string[];
}

export interface SaveResponsesRequest {
  sessionId?: string;
  responses: QuestionResponse[];
  isComplete?: boolean;
}

export interface SaveResponsesResponse {
  success: boolean;
  message: string;
  sessionId: string;
  data?: {
    assessment_id: string;
    session_id: string;
  };
}

export interface ResponseData {
  question_id: string;
  question_text: string;
  value: string | string[];
}

export interface DetailedAssessmentResult {
  session_id: string;
  status: string;
  requires_certification: boolean;
  certification_reasons: string[];
  summary: string;
  responses: ResponseData[];
  created_at: string;
}

export interface AssessmentSummary {
  session_id: string;
  status: string;
  requires_certification: boolean;
  created_at: string;
}

// Certification outcome determination function
export interface CertificationOutcome {
  requires_certification: boolean;
  certification_reasons: string[];
  summary: string;
}

// Fallback questions in case the API call fails
const fallbackQuestions: AssessmentQuestion[] = [
  {
    question_id: "Q1",
    text: "Is your organisation registered in DIFC?",
    type: "yes_no",
    order_num: 1,
    is_required: true,
    guidance_text: "DIFC Regulation 10 applies to organisations registered in DIFC."
  },
  {
    question_id: "Q1a",
    text: "Please provide your DIFC registration number:",
    type: "number",
    order_num: 2,
    is_required: true,
    parent_question_id: "Q1",
    parent_answer: "Yes",
    guidance_text: "Your DIFC registration number can be found on your registration certificate."
  },
  {
    question_id: "Q1b",
    text: "Please select the country where your organisation is registered",
    type: "dropdown",
    order_num: 3,
    is_required: true,
    parent_question_id: "Q1",
    parent_answer: "No",
    guidance_text: "Please select the country where your organisation is registered.",
    options: [
      { option_id: "UAE", option_value: "UAE", option_text: "United Arab Emirates (outside DIFC)" },
      { option_id: "UK", option_value: "UK", option_text: "United Kingdom" },
      { option_id: "US", option_value: "US", option_text: "United States" },
      { option_id: "EU", option_value: "EU", option_text: "European Union" },
      { option_id: "Other", option_value: "Other", option_text: "Other" }
    ]
  },
  {
    question_id: "Q1c",
    text: "Is your AI system intended to target individuals or organisations in DIFC?",
    type: "yes_no",
    order_num: 4,
    is_required: true,
    parent_question_id: "Q1",
    parent_answer: "No",
    guidance_text: "This information determines if DIFC regulations apply to your AI system even if you are not registered in DIFC."
  },
  {
    question_id: "Q2",
    text: "How would you classify your organisation's role regarding AI systems?",
    type: "dropdown",
    order_num: 5,
    is_required: true,
    guidance_text: "Provider: An organisation that develops, creates, or modifies AI systems. Examples include AI software developers, companies creating machine learning models, or businesses that adapt existing AI solutions.\n\nDeployer: An organisation that makes the decision to use an AI system in its operations. Examples include businesses implementing AI tools for customer service, financial institutions using AI for risk assessment, or retailers using recommendation engines.\n\nOperator: An organisation that uses or operates an AI system without having control over its development or deployment decisions. Examples include employees using AI tools provided by their employer or contractors operating AI systems on behalf of another company.\n\nIf you have multiple roles, select your primary role or the one most relevant to the AI system being assessed.",
    options: [
      { option_id: "Provider", option_value: "Provider", option_text: "Provider" },
      { option_id: "Deployer", option_value: "Deployer", option_text: "Deployer" },
      { option_id: "Operator", option_value: "Operator", option_text: "Operator" },
      { option_id: "NotSure", option_value: "NotSure", option_text: "Not sure" }
    ]
  },
  {
    question_id: "Q2a",
    text: "What is the name or identifier of your AI system?",
    type: "text",
    order_num: 6,
    is_required: true,
    guidance_text: "Provide a unique name or identifier for the AI system being assessed. This will help identify this specific system in your assessment results and any future communications."
  },
  {
    question_id: "Q3",
    text: "What type of AI system is being assessed?",
    type: "text",
    order_num: 7,
    is_required: true,
    guidance_text: "Provide a brief description of your AI system, including its purpose and functionality."
  },
  {
    question_id: "Q4",
    text: "Does the AI system process personal data?",
    type: "dropdown",
    order_num: 8,
    is_required: true,
    guidance_text: "Personal data includes any information relating to an identified or identifiable natural person.",
    options: [
      { option_id: "Yes", option_value: "Yes", option_text: "Yes" },
      { option_id: "No", option_value: "No", option_text: "No" },
      { option_id: "NotSure", option_value: "NotSure", option_text: "Not sure" }
    ]
  },
  {
    question_id: "Q4a",
    text: "What types of personal data does the AI system process?",
    type: "multiple_choice",
    order_num: 9,
    is_required: true,
    parent_question_id: "Q4",
    parent_answer: "Yes",
    guidance_text: "Select all types of personal data that apply.",
    options: [
      { option_id: "customer_data", option_value: "customer_data", option_text: "Customer/User Data" },
      { option_id: "employee_data", option_value: "employee_data", option_text: "Employee Data" },
      { option_id: "financial_data", option_value: "financial_data", option_text: "Financial Data" },
      { option_id: "health_data", option_value: "health_data", option_text: "Health Data" },
      { option_id: "biometric_data", option_value: "biometric_data", option_text: "Biometric Data" },
      { option_id: "location_data", option_value: "location_data", option_text: "Location Data" },
      { option_id: "other", option_value: "other", option_text: "Other" }
    ]
  },
  {
    question_id: "Q5",
    text: "Is or will your AI system be used for any high-risk applications?",
    type: "dropdown",
    order_num: 10,
    is_required: true,
    guidance_text: "High-risk applications include those that could impact health, safety, rights, or have legal effects on individuals.",
    options: [
      { option_id: "Yes", option_value: "Yes", option_text: "Yes" },
      { option_id: "No", option_value: "No", option_text: "No" },
      { option_id: "NotSure", option_value: "NotSure", option_text: "Not sure" }
    ]
  },
  {
    question_id: "Q5a",
    text: "Select the high-risk categories that apply to your AI system:",
    type: "multiple_choice",
    order_num: 11,
    is_required: true,
    parent_question_id: "Q5",
    parent_answer: "Yes",
    guidance_text: "Select all categories that apply to your AI system.",
    options: [
      { option_id: "critical_infrastructure", option_value: "critical_infrastructure", option_text: "Critical Infrastructure" },
      { option_id: "education_vocational", option_value: "education_vocational", option_text: "Education or Vocational Training" },
      { option_id: "employment", option_value: "employment", option_text: "Employment, Worker Management or Access to Self-Employment" },
      { option_id: "essential_services", option_value: "essential_services", option_text: "Access to Essential Private or Public Services" },
      { option_id: "law_enforcement", option_value: "law_enforcement", option_text: "Law Enforcement" },
      { option_id: "migration_asylum", option_value: "migration_asylum", option_text: "Migration, Asylum and Border Control Management" },
      { option_id: "administration_justice", option_value: "administration_justice", option_text: "Administration of Justice and Democratic Processes" },
      { option_id: "other", option_value: "other", option_text: "Other" }
    ]
  },
  {
    question_id: "Q6",
    text: "Is the AI system to be used by an Accredited Certification Body?",
    type: "yes_no",
    order_num: 12,
    is_required: true,
    guidance_text: "An Accredited Certification Body is an organization authorized to assess conformity with certification standards."
  }
];

// API functions
export const getAssessmentQuestions = async (): Promise<AssessmentQuestion[]> => {
  try {
    console.log('Fetching assessment questions...');
    const response = await axiosInstance.get(QUESTIONS_URL);
    if (response.data && response.data.success) {
      console.log('Successfully fetched assessment questions');
      
      // Check if Question 6 is missing
      const questions = response.data.data as AssessmentQuestion[];
      const hasQ6 = questions.some(q => q.question_id === "Q6" || q.text.includes("Accredited Certification Body"));
      
      if (!hasQ6) {
        console.log('Question 6 is missing, appending it to the list');
        const q6 = fallbackQuestions.find(q => q.question_id === "Q6");
        if (q6) {
          questions.push(q6);
          // Sort by order_num
          questions.sort((a, b) => a.order_num - b.order_num);
        }
      }
      
      return questions;
    } else {
      console.error('Failed to fetch assessment questions:', response.data?.message);
      console.log('Using fallback questions');
      return fallbackQuestions;
    }
  } catch (error: any) {
    console.error('Error fetching assessment questions:', error.message);
    console.log('Using fallback questions due to error');
    return fallbackQuestions;
  }
};

// Function to get or create a session ID
export const getOrCreateSession = async (): Promise<string> => {
  try {
    // First, try to get a session from localStorage
    const storedSession = localStorage.getItem('assessment_session');
    if (storedSession) {
      console.log('Using stored session ID:', storedSession);
      
      // Validate the session with the backend
      const validateResponse = await axiosInstance.post(SESSION_MANAGER_URL, {
        sessionId: storedSession,
        userId: getUserIdFromLocalStorage()
      });
      
      if (validateResponse.data && validateResponse.data.success) {
        console.log('Session validated successfully:', validateResponse.data.sessionId);
        return validateResponse.data.sessionId;
      }
    }
    
    // If we get here, either there was no stored session or it was invalid
    // Create a new session
    console.log('Creating new session...');
    const createResponse = await axiosInstance.post(SESSION_MANAGER_URL, {
      userId: getUserIdFromLocalStorage()
    });
    
    if (createResponse.data && createResponse.data.success) {
      const newSessionId = createResponse.data.sessionId;
      console.log('New session created:', newSessionId);
      
      // Store the session ID in localStorage
      localStorage.setItem('assessment_session', newSessionId);
      
      return newSessionId;
    } else {
      throw new Error('Failed to create session');
    }
  } catch (error) {
    console.error('Error in getOrCreateSession:', error);
    
    // Fallback: generate a client-side session ID if server fails
    const fallbackSessionId = 'fallback_' + Math.random().toString(36).substring(2, 15);
    console.warn('Using fallback session ID:', fallbackSessionId);
    localStorage.setItem('assessment_session', fallbackSessionId);
    
    return fallbackSessionId;
  }
};

// Helper to get user ID from localStorage
const getUserIdFromLocalStorage = (): number | null => {
  try {
    const user = localStorage.getItem('user');
    if (user) {
      const userData = JSON.parse(user);
      return userData.id || null;
    }
  } catch (error) {
    console.error('Error getting user ID from localStorage:', error);
  }
  return null;
};

// Create a non-authenticated axios instance for public endpoints
const publicAxiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Update saveAssessmentResponses to use session management
export const saveAssessmentResponses = async (data: SaveResponsesRequest): Promise<SaveResponsesResponse> => {
  try {
    console.log('Saving assessment responses...', data);
    
    // Add validation to ensure we have actual response data
    if (!data.responses || data.responses.length === 0) {
      console.error('No responses provided to save');
      throw new Error('No responses provided to save');
    }
    
    // If no session ID is provided, get or create one
    if (!data.sessionId) {
      data.sessionId = await getOrCreateSession();
      console.log('Using session ID for response save:', data.sessionId);
    }
    
    // Log each response being saved for debugging
    data.responses.forEach(response => {
      console.log(`Saving response for question ${response.questionId}:`, response.value);
    });
    
    // Save responses to localStorage as a reliable fallback first
    try {
      const localResponses = Object.fromEntries(
        data.responses.map(r => [r.questionId, r.value])
      );
      localStorage.setItem('assessment_responses', JSON.stringify(localResponses));
      console.log('Saved responses to localStorage as primary fallback');
      
      // Also store the session ID
      localStorage.setItem('assessment_session', data.sessionId || '');
    } catch (storageError) {
      console.error('Failed to save to localStorage:', storageError);
    }
    
    // Try with authenticated instance first
    try {
      const response = await axiosInstance.post(RESPONSES_URL, data);
      if (response.data && response.data.success) {
        console.log('Successfully saved assessment responses');
        
        // If we got a session ID back, update local storage
        if (response.data.sessionId) {
          localStorage.setItem('assessment_session', response.data.sessionId);
        }
        
        return response.data;
      } else {
        throw new Error(response.data?.message || 'Error saving assessment responses');
      }
    } catch (authError: any) {
      // If we get an authentication error, try again with the public instance
      if (authError.message && authError.message.includes('Authorization')) {
        console.log('Authentication error, trying without authentication...');
        try {
          const publicResponse = await publicAxiosInstance.post(RESPONSES_URL, data);
          if (publicResponse.data && publicResponse.data.success) {
            console.log('Successfully saved assessment responses without authentication');
            
            // If we got a session ID back, update local storage
            if (publicResponse.data.sessionId) {
              localStorage.setItem('assessment_session', publicResponse.data.sessionId);
            }
            
            return publicResponse.data;
          } else {
            throw new Error(publicResponse.data?.message || 'Error saving assessment responses');
          }
        } catch (publicError) {
          console.error('Error with public instance:', publicError);
          // Return a mock successful response since we already saved to localStorage
          return {
            success: true,
            message: 'Responses saved locally',
            sessionId: data.sessionId || localStorage.getItem('assessment_session') || 'local_session',
            data: {
              assessment_id: 'local_assessment',
              session_id: data.sessionId || localStorage.getItem('assessment_session') || 'local_session'
            }
          };
        }
      } else {
        // For non-authentication errors, return a mock successful response
        console.error('Non-authentication error:', authError);
        return {
          success: true,
          message: 'Responses saved locally',
          sessionId: data.sessionId || localStorage.getItem('assessment_session') || 'local_session',
          data: {
            assessment_id: 'local_assessment',
            session_id: data.sessionId || localStorage.getItem('assessment_session') || 'local_session'
          }
        };
      }
    }
  } catch (error: any) {
    console.error('Error saving assessment responses:', error);
    
    // Save responses to localStorage as fallback
    try {
      const localResponses = Object.fromEntries(
        data.responses.map(r => [r.questionId, r.value])
      );
      localStorage.setItem('assessment_responses', JSON.stringify(localResponses));
      console.log('Saved responses to localStorage as fallback');
    } catch (storageError) {
      console.error('Failed to save to localStorage:', storageError);
    }
    
    throw error;
  }
};

// Update getAssessmentResults to use session management
export const getAssessmentResults = async (params: { sessionId?: string; assessmentId?: string }): Promise<DetailedAssessmentResult | AssessmentSummary[]> => {
  try {
    // If no session ID is provided, get or create one
    if (!params.sessionId) {
      params.sessionId = await getOrCreateSession();
      console.log('Using session ID for results fetch:', params.sessionId);
    }
    
    console.log('Fetching assessment results...', params);
    
    const url = `${RESULTS_URL}?sessionId=${params.sessionId}${params.assessmentId ? `&assessmentId=${params.assessmentId}` : ''}`;
    const response = await axiosInstance.get(url);
    
    if (response.data && response.data.success) {
      console.log('Successfully fetched assessment results');
      return response.data.data;
    } else {
      throw new Error(response.data?.message || 'No assessment results found');
    }
  } catch (error: any) {
    console.error('Error fetching assessment results:', error);
    
    // Try to use data from localStorage as fallback
    console.log('Using local storage as fallback for results');
    const localResponses = localStorage.getItem('assessment_responses');
    if (localResponses) {
      const responses = JSON.parse(localResponses);
      if (responses && Object.keys(responses).length > 0) {
        return determineAssessmentOutcomeFromLocal(responses);
      }
    }
    
    throw new Error('No assessment results found');
  }
};

// Certification outcome determination function
export const determineAssessmentOutcome = (responses: Record<string, string | string[]>): CertificationOutcome => {
  console.log('Determining assessment outcome based on responses:', responses);
  
  // Extract responses to relevant questions
  const q1Response = responses['Q1'] as string || ''; // "Is your organisation registered in DIFC?"
  const q1cResponse = responses['Q1c'] as string || ''; // "Is your AI system intended to target individuals or organisations in DIFC?"
  const q2Response = responses['Q2'] as string || ''; // "How would you classify your organisation's role regarding AI systems?"
  const q4Response = responses['Q4'] as string || ''; // "Does the AI system process personal data?"
  const q5Response = responses['Q5'] as string || ''; // "Is or will your AI system be used for any high-risk applications?"
  const q6Response = responses['Q6'] as string || ''; // "Is the AI system to be used by an Accredited Certification Body?"
  
  // Check for "Not sure" responses on any key questions
  if (q2Response === 'NotSure' || q4Response === 'NotSure' || q5Response === 'NotSure') {
    const summary = "Your responses have been recorded. Our team can provide further guidance and support in completing your assessment. Please contact us by clicking the Contact Button";
    const certification_reasons = ["User selected 'Not Sure' for critical questions"];
    return { requires_certification: false, certification_reasons, summary };
  }
  
  // Convert responses to boolean values
  const Q1 = q1Response === 'Yes'; // Registered in DIFC
  const Q1a = q1cResponse === 'Yes'; // AI system targets DIFC
  const Q4 = q4Response === 'Yes'; // Processes personal data
  const Q5 = q5Response === 'Yes'; // High-risk application
  const Q6 = q6Response === 'Yes'; // Used by Accredited Certification Body
  
  console.log('Evaluation values:', { Q1, Q1a, Q4, Q5, Q6 });
  console.log('Raw question responses:', { q1Response, q1cResponse, q4Response, q5Response, q6Response });
  
  let requires_certification = false;
  let certification_reasons: string[] = [];
  let summary = '';
  
  // Check if we have enough data to make a determination
  const hasRequiredResponses = q1Response && q4Response && q5Response;
  
  if (!hasRequiredResponses) {
    summary = "Insufficient information to determine certification requirements. Please ensure all key questions are answered.";
    certification_reasons.push("Missing responses to key questions");
    return { requires_certification: false, certification_reasons, summary };
  }
  
  // CONDITION 1: Certification NOT Required - Non-DIFC with personal data but not high-risk
  if ((!Q1 && Q1a) && (Q4 && !Q5)) {
    requires_certification = false;
    summary = "Your AI System is not subject to mandatory certification under DIFC Regulation 10. " +
      "However, you must still ensure compliance with relevant data protection laws or AI regulations in the jurisdiction " +
      "where you operate or deploy the AI system. Your AI System may be of High-Risk type, which may require certification " +
      "in your jurisdiction of operation.";
    certification_reasons.push("Not located in DIFC but targets DIFC");
    certification_reasons.push("Processes personal data but not high-risk");
  }
  // CONDITION 2: Certification NOT Required - DIFC with personal data but not high-risk
  else if ((Q1 || Q1a) && (Q4 && !Q5) && (!Q6)) {
    requires_certification = false;
    summary = "Your AI System is not subject to mandatory certification under DIFC Regulation 10. " +
      "However, you must still ensure compliance with relevant data protection laws or AI regulations in the jurisdiction " +
      "where you operate or deploy the AI system.";
    certification_reasons.push("Located in or targets DIFC");
    certification_reasons.push("Processes personal data but not high-risk");
  }
  // CONDITION 3: Certification REQUIRED - DIFC with personal data and high-risk
  else if ((Q1 || Q1a) && (Q4 && Q5) && (!Q6)) {
    requires_certification = true;
    summary = "Your system is subject to mandatory certification under DIFC Regulation 10. " +
      "Please proceed to Part B of the questionnaire and prepare the necessary evidence.";
    certification_reasons.push("Located in or targets DIFC");
    certification_reasons.push("Processes personal data and is high-risk");
  }
  // CONDITION 4: Certification REQUIRED - DIFC with no personal data but high-risk
  else if ((Q1 || Q1a) && (!Q4 && Q5) && (!Q6)) {
    requires_certification = true;
    summary = "Your system is subject to mandatory certification under DIFC Regulation 10. " +
      "Please proceed to Part B of the questionnaire and prepare the necessary evidence.";
    certification_reasons.push("Located in or targets DIFC");
    certification_reasons.push("High-risk AI system");
  }
  // CONDITION 5: Conflict of Interest
  else if ((Q1 || Q1a) && (Q5) && (Q6)) {
    requires_certification = true;
    summary = "Your system is subject to mandatory certification under DIFC Regulation 10. However, " +
      "due to a potential conflict of interest, you must seek certification from an independent Accreditation Body.";
    certification_reasons.push("Located in or targets DIFC");
    certification_reasons.push("High-risk AI system");
    certification_reasons.push("Potential conflict of interest with Accredited Certification Body");
  }
  // Default case
  else {
    summary = "Based on your responses, we cannot determine certification requirements. Please review your inputs or contact support for assistance.";
    certification_reasons.push("Undetermined certification status");
  }
  
  console.log('Assessment outcome:', { requires_certification, certification_reasons, summary });
  
  return {
    requires_certification,
    certification_reasons,
    summary
  };
};

// Determine assessment outcome from local storage data
const determineAssessmentOutcomeFromLocal = (responses: Record<string, string | string[]>): DetailedAssessmentResult => {
  console.log('Determining assessment outcome based on responses:', responses);
  
  const outcome = determineAssessmentOutcome(responses);
  
  // Create a DetailedAssessmentResult object from the outcome
  return {
    session_id: localStorage.getItem('assessment_session') || 'local_fallback',
    status: outcome.requires_certification ? 'CERTIFICATION_REQUIRED' : 'NO_CERTIFICATION_REQUIRED',
    requires_certification: outcome.requires_certification,
    certification_reasons: outcome.certification_reasons,
    summary: outcome.summary,
    responses: Object.entries(responses).map(([questionId, value]) => ({
      question_id: questionId,
      question_text: getQuestionTextById(questionId, fallbackQuestions),
      value
    })),
    created_at: new Date().toISOString()
  };
};

// Helper to get question text by ID from a list of questions
const getQuestionTextById = (questionId: string, questions: AssessmentQuestion[]): string => {
  for (const question of questions) {
    if (question.question_id === questionId) {
      return question.text;
    }
  }
  return 'Unknown Question';
};
