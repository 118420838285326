import axios from 'axios';
import { getOrCreateSession } from './assessmentService';

// API endpoints
const COMPLIANCE_URL = '/api/php-backend/compliance-assessment.php';

// Configure axios with auth header
const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Get token from local storage
const getToken = (): string | null => {
  const user = localStorage.getItem('user');
  if (user) {
    try {
      const userData = JSON.parse(user);
      return userData.token;
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  }
  return null;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // Also set token as cookie as fallback
      document.cookie = `token=${token}; path=/; secure; samesite=strict`;
    }
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Types for compliance assessment
export interface ComplianceFormData {
  aiSystemName: string;
  aiSystemVersion: string;
  aiSystemDescription: string;
  riskMitigationMeasures: string;
  dataGovernancePolicies: string;
  humanOversightMeasures: string;
  technicalDocumentation: string;
  additionalInformation: string;
}

export interface SaveComplianceRequest {
  sessionId: string;
  aiSystemName: string;
  aiSystemVersion: string;
  aiSystemDescription: string;
  riskMitigationMeasures: string;
  dataGovernancePolicies?: string;
  humanOversightMeasures?: string;
  technicalDocumentation?: string;
  additionalInformation?: string;
}

export interface SaveComplianceResponse {
  success: boolean;
  message: string;
  data: {
    session_id: string;
  };
}

export interface GetComplianceResponse {
  success: boolean;
  message?: string;
  data: ComplianceFormData & {
    session_id: string;
  };
}

// Save compliance assessment data
export const saveComplianceData = async (data: SaveComplianceRequest): Promise<SaveComplianceResponse> => {
  try {
    console.log('Saving compliance assessment data...', data);
    
    // If no session ID is provided, get or create one
    if (!data.sessionId) {
      data.sessionId = await getOrCreateSession();
      console.log('Using session ID for compliance save:', data.sessionId);
    }
    
    const response = await axiosInstance.post(COMPLIANCE_URL, data);
    if (response.data && response.data.success) {
      console.log('Successfully saved compliance assessment data');
      
      // Save to localStorage as well for backup
      localStorage.setItem(`compliance_form_${data.sessionId}`, JSON.stringify({
        aiSystemName: data.aiSystemName,
        aiSystemVersion: data.aiSystemVersion,
        aiSystemDescription: data.aiSystemDescription,
        riskMitigationMeasures: data.riskMitigationMeasures,
        dataGovernancePolicies: data.dataGovernancePolicies || '',
        humanOversightMeasures: data.humanOversightMeasures || '',
        technicalDocumentation: data.technicalDocumentation || '',
        additionalInformation: data.additionalInformation || ''
      }));
      
      return response.data;
    } else {
      throw new Error(response.data?.message || 'Error saving compliance assessment data');
    }
  } catch (error: any) {
    console.error('Error saving compliance assessment data:', error);
    
    // Save to localStorage even if API fails
    if (data.sessionId) {
      localStorage.setItem(`compliance_form_${data.sessionId}`, JSON.stringify({
        aiSystemName: data.aiSystemName,
        aiSystemVersion: data.aiSystemVersion,
        aiSystemDescription: data.aiSystemDescription,
        riskMitigationMeasures: data.riskMitigationMeasures,
        dataGovernancePolicies: data.dataGovernancePolicies || '',
        humanOversightMeasures: data.humanOversightMeasures || '',
        technicalDocumentation: data.technicalDocumentation || '',
        additionalInformation: data.additionalInformation || ''
      }));
    }
    
    throw error;
  }
};

// Get compliance assessment data
export const getComplianceData = async (sessionId: string): Promise<ComplianceFormData> => {
  try {
    console.log('Fetching compliance assessment data...', sessionId);
    
    const url = `${COMPLIANCE_URL}?sessionId=${sessionId}`;
    const response = await axiosInstance.get<GetComplianceResponse>(url);
    
    if (response.data && response.data.success) {
      console.log('Successfully fetched compliance assessment data');
      return response.data.data;
    } else {
      throw new Error(response.data?.message || 'No compliance assessment data found');
    }
  } catch (error: any) {
    console.error('Error fetching compliance assessment data:', error);
    
    // Try to use data from localStorage as fallback
    console.log('Using local storage as fallback for compliance data');
    const localData = localStorage.getItem(`compliance_form_${sessionId}`);
    if (localData) {
      try {
        const parsedData = JSON.parse(localData);
        return parsedData as ComplianceFormData;
      } catch (err) {
        console.error('Error parsing local compliance data:', err);
      }
    }
    
    throw new Error('No compliance assessment data found');
  }
};

// Submit compliance assessment for review
export const submitComplianceAssessment = async (data: SaveComplianceRequest): Promise<SaveComplianceResponse> => {
  try {
    console.log('Submitting compliance assessment for review...', data);
    
    // Add a flag to indicate this is a final submission
    const submitData = {
      ...data,
      isComplete: true
    };
    
    const response = await axiosInstance.post(COMPLIANCE_URL, submitData);
    if (response.data && response.data.success) {
      console.log('Successfully submitted compliance assessment for review');
      return response.data;
    } else {
      throw new Error(response.data?.message || 'Error submitting compliance assessment');
    }
  } catch (error: any) {
    console.error('Error submitting compliance assessment:', error);
    throw error;
  }
};
