import React from 'react';
// Import the CSS for decorative elements
import '../styles/decorations.css';
// Import the logo
import logo from '../assets/images/WL-logo-white.png';
// Import Asset 8 directly
import asset8 from '../assets/images/Asset 8 copy.svg';

const Footer: React.FC = () => {
  return (
    <footer className="bg-brand-dark-blue text-white py-6 relative overflow-hidden">
      {/* Background decorative element - concentric circles */}
      <div className="absolute -left-16 -top-16 opacity-10 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <div className="flex items-center mb-2">
              {/* Use the white logo */}
              <img src={logo} alt="White Label Consultancy" className="h-8 w-auto" />
            </div>
            <p className="text-brand-very-light-blue mt-1">
              Ensuring compliance with DIFC Regulation 10
            </p>
          </div>
          <div className="text-center md:text-right">
            <p>&copy; {new Date().getFullYear()} White Label Consultancy. All rights reserved.</p>
            <div className="mt-2">
              <a href="#privacy" className="text-brand-muted-blue hover:text-brand-light-blue transition-colors duration-200 mr-4">
                Privacy Policy
              </a>
              <a href="#terms" className="text-brand-muted-blue hover:text-brand-light-blue transition-colors duration-200">
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 