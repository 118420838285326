import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

// Define the shape of the user object
interface User {
  _id: string;
  name: string;
  email: string;
  token: string;
  privacy_terms_accepted?: boolean;
}

// Define the shape of the auth context
interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  register: (name: string, email: string, password: string, privacy_terms_accepted: boolean) => Promise<void>;
  logout: () => void;
}

// Create the auth context with a default value
export const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
  error: null,
  login: async () => {},
  register: async () => {},
  logout: () => {},
});

// Define props for AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// Create the auth provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // API base URL - updated to point directly to the PHP API endpoints
  const API_URL = '/api'; // Direct path to the API directory
  
  // Cache busting - updated March 15, 2024 to use React-specific endpoints
  const REGISTER_URL = `${API_URL}/react_register.php`;
  const LOGIN_URL = `${API_URL}/react_login.php`;

  // Check if user is already logged in (on component mount)
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
      } catch (err) {
        console.error('Error parsing user data from localStorage:', err);
        localStorage.removeItem('user');
      }
    }
    setLoading(false);
  }, []);

  // Set auth token for all requests
  useEffect(() => {
    if (user && user.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [user]);

  // Login user
  const login = async (email: string, password: string) => {
    setLoading(true);
    setError(null);
    
    try {
      // const loginUrl = `${API_URL}/react_login.php`; // Updated to use React-specific endpoint
      console.log(`Attempting login at: ${LOGIN_URL}`);
      
      const response = await axios.post(LOGIN_URL, { email, password });
      console.log('Login response:', response.data);
      
      if (response.data && (response.data.success || response.data.token || (response.data.user && response.data.user.token))) {
        // Extract user data based on response format
        let userData: User;
        
        if (response.data.user) {
          userData = {
            _id: response.data.user.id || '1', // Use id or default to '1'
            name: response.data.user.name,
            email: response.data.user.email,
            token: response.data.token || response.data.user.token,
            privacy_terms_accepted: response.data.user.privacy_terms_accepted || false
          };
        } else {
          userData = {
            _id: response.data.id || response.data._id || '1', // Multiple fallbacks
            name: response.data.name || '',
            email: response.data.email || '',
            token: response.data.token || '',
            privacy_terms_accepted: response.data.privacy_terms_accepted || false
          };
        }
        
        // Log the user data to aid debugging
        console.log('Processed user data for saving:', userData);
        
        // Save user data
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        throw new Error(response.data.message || 'Invalid login response');
      }
    } catch (err: any) {
      console.error('Login error:', err);
      const errorMessage = err.response?.data?.message || err.message || 'Invalid credentials';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Register user
  const register = async (name: string, email: string, password: string, privacy_terms_accepted: boolean) => {
    setLoading(true);
    setError(null);
    
    try {
      // const registerUrl = `${API_URL}/react_register.php`; // Updated to use React-specific endpoint
      console.log(`Attempting registration at: ${REGISTER_URL}`);
      console.log('Registration data:', { name, email, password: '********', privacy_terms_accepted });
      
      const response = await axios.post(REGISTER_URL, { 
        name, 
        email, 
        password,
        privacy_terms_accepted
      });
      console.log('Registration response:', response.data);
      
      // Check for error in response
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }
      
      if (response.data && (response.data.success || response.data.token || (response.data.user && response.data.user.token))) {
        // Mark this as a new registration
        localStorage.setItem('recent_registration', 'true');
        
        // Extract user data based on response format
        let userData: User;
        
        if (response.data.user) {
          userData = {
            _id: response.data.user._id || response.data.user.id || '1', // Try _id first, then id
            name: response.data.user.name,
            email: response.data.user.email,
            token: response.data.user.token || response.data.token,
            privacy_terms_accepted: response.data.user.privacy_terms_accepted || privacy_terms_accepted
          };
        } else {
          userData = {
            _id: response.data._id || response.data.id || '1', // Try _id first, then id
            name: response.data.name || name,
            email: response.data.email || email,
            token: response.data.token || '',
            privacy_terms_accepted: response.data.privacy_terms_accepted || privacy_terms_accepted
          };
        }
        
        // Log the user data to aid debugging
        console.log('Processed user data for saving:', userData);
        
        // Save user data
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        console.log('Registration successful, user data saved and recent_registration flag set');
      } else {
        throw new Error(response.data.message || response.data.error || 'Invalid registration response');
      }
    } catch (err: any) {
      console.error('Registration error:', err);
      console.error('Error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status
      });
      
      const errorMessage = err.response?.data?.error || err.response?.data?.message || err.message || 'Registration failed. Please try again.';
      setError(errorMessage);
      throw err; // Rethrow to allow caller to catch
    } finally {
      setLoading(false);
    }
  };

  // Logout user
  const logout = () => {
    // Clear all user-related data from localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('applicant_form_data');
    localStorage.removeItem('recent_registration');
    localStorage.removeItem('assessment_session');
    localStorage.removeItem('assessment_responses');
    
    // Log the cleanup
    console.log('User logged out, all related data cleared from localStorage');
    
    // Clear the user state
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        login,
        register,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}; 