import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssessmentQuestion,
  QuestionResponse,
  getAssessmentQuestions,
  saveAssessmentResponses
} from '../services/assessmentService';

interface AssessmentQuestionnaireProps {
  sessionId?: string;
  onComplete?: (sessionId: string) => void;
}

const AssessmentQuestionnaire: React.FC<AssessmentQuestionnaireProps> = ({
  sessionId: initialSessionId,
  onComplete
}) => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<AssessmentQuestion[]>([]);
  const [responses, setResponses] = useState<Record<string, string | string[]>>({});
  const [sessionId, setSessionId] = useState<string | undefined>(initialSessionId);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  // Fetch questions on component mount
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        setError(null);
        const fetchedQuestions = await getAssessmentQuestions();
        setQuestions(fetchedQuestions);
      } catch (err: any) {
        setError(err.message || 'Failed to load assessment questions');
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  // Get visible questions based on parent-child relationships and current responses
  const getVisibleQuestions = useCallback(() => {
    const result: AssessmentQuestion[] = [];
    
    // Helper function to process questions recursively
    const processQuestions = (questionsArray: AssessmentQuestion[]) => {
      for (const question of questionsArray) {
        // For top-level questions or questions with matching parent conditions
        if (!question.parent_question_id || 
            (responses[question.parent_question_id] === question.parent_answer)) {
          result.push(question);
          
          // Process children if any
          if (question.children && question.children.length > 0) {
            processQuestions(question.children);
          }
        }
      }
    };
    
    processQuestions(questions);
    return result;
  }, [questions, responses]);

  // Determine next visible step for navigation
  const visibleQuestions = getVisibleQuestions();

  // Save responses periodically or when moving between steps
  const saveResponses = async (isComplete = false) => {
    if (Object.keys(responses).length === 0) return;
    
    try {
      setIsSubmitting(true);
      setError(null);
      
      // Format responses for API
      const questionResponses: QuestionResponse[] = Object.entries(responses).map(
        ([questionId, value]) => ({
          questionId,
          value
        })
      );
      
      console.log('Preparing to save responses:', questionResponses);
      
      // Add the question text to each response for clarity in results
      const enhancedResponses = questionResponses.map(response => {
        const question = questions.find(q => q.question_id === response.questionId);
        const questionText = question ? question.text : 'Unknown Question';
        console.log(`Mapped question ${response.questionId} to text: "${questionText}"`);
        return {
          ...response,
          questionText
        };
      });
      
      console.log('Enhanced responses with question text:', enhancedResponses);
      
      try {
        const result = await saveAssessmentResponses({
          sessionId,
          responses: questionResponses,
          isComplete
        });
        
        // Update session ID
        setSessionId(result.sessionId);
        console.log('Responses saved with session ID:', result.sessionId);
        
        // If complete and onComplete callback provided, call it
        if (isComplete && onComplete) {
          onComplete(result.sessionId);
        }
        
        return result;
      } catch (saveErr: any) {
        console.error('Error saving responses to server:', saveErr);
        
        // Don't show error to user, just log it
        console.log('Continuing with locally saved data');
        
        // Create a mock result using the session ID
        const mockResult = {
          success: true,
          message: 'Using locally saved data',
          sessionId: sessionId || localStorage.getItem('assessment_session') || 'local_session',
          data: {
            assessment_id: 'local_assessment',
            session_id: sessionId || localStorage.getItem('assessment_session') || 'local_session'
          }
        };
        
        // If complete and onComplete callback provided, call it with the mock result
        if (isComplete && onComplete) {
          onComplete(mockResult.sessionId);
        }
        
        return mockResult;
      }
    } catch (err: any) {
      console.error('Error in saveResponses function:', err);
      // Don't show error to user, just log it
      return {
        success: true,
        message: 'Using locally saved data',
        sessionId: sessionId || localStorage.getItem('assessment_session') || 'local_session'
      };
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle response changes
  const handleResponseChange = (questionId: string, value: string | string[]) => {
    console.log(`Response changed for question ${questionId}:`, value);
    setResponses(prev => {
      const newResponses = {
        ...prev,
        [questionId]: value
      };
      console.log('Updated responses state:', newResponses);
      
      // Store current responses in local storage as a fallback
      try {
        const questionText = questions.find(q => q.question_id === questionId)?.text || '';
        const responseWithText = {
          questionId,
          questionText,
          value
        };
        
        // Get existing responses from local storage or initialize a new array
        const storedResponses = JSON.parse(localStorage.getItem('assessmentResponses') || '[]');
        
        // Check if this question already has a response and update it
        const existingResponseIndex = storedResponses.findIndex(
          (r: any) => r.questionId === questionId
        );
        
        if (existingResponseIndex >= 0) {
          storedResponses[existingResponseIndex] = responseWithText;
        } else {
          storedResponses.push(responseWithText);
        }
        
        // Store updated responses
        localStorage.setItem('assessmentResponses', JSON.stringify(storedResponses));
        localStorage.setItem('assessmentResponsesTimestamp', new Date().toISOString());
        
        console.log('Stored response in local storage:', responseWithText);
      } catch (err) {
        console.error('Failed to store response in local storage:', err);
      }
      
      return newResponses;
    });
  };

  // Get current question based on step
  const currentQuestion = visibleQuestions[currentStep];

  // Handle next step
  const handleNext = async () => {
    try {
      // Validate current response
      if (currentQuestion.is_required && 
          (responses[currentQuestion.question_id] === undefined || 
           responses[currentQuestion.question_id] === '' ||
           (Array.isArray(responses[currentQuestion.question_id]) && 
            (responses[currentQuestion.question_id] as string[]).length === 0))) {
        setError(`Please answer this question before proceeding`);
        return;
      }
      
      // Save responses
      await saveResponses();
      
      // Move to next question
      if (currentStep < visibleQuestions.length - 1) {
        setCurrentStep(currentStep + 1);
        setError(null);
      }
    } catch (err) {
      // Error is set by saveResponses
    }
  };

  // Handle previous step
  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setError(null);
    }
  };

  // Handle submission
  const handleSubmit = async () => {
    try {
      // Validate current response
      if (currentQuestion.is_required && 
          (responses[currentQuestion.question_id] === undefined || 
           responses[currentQuestion.question_id] === '' ||
           (Array.isArray(responses[currentQuestion.question_id]) && 
            (responses[currentQuestion.question_id] as string[]).length === 0))) {
        setError(`Please answer this question before submitting`);
        return;
      }
      
      console.log('Submitting final responses:', responses);
      
      // Store final responses in local storage
      try {
        // Transform responses into an array with question text
        const finalResponsesArray = Object.entries(responses).map(([questionId, value]) => {
          const question = questions.find(q => q.question_id === questionId);
          return {
            questionId,
            questionText: question?.text || 'Unknown Question',
            value
          };
        });
        
        // Store the complete set of responses
        localStorage.setItem('assessmentFinalResponses', JSON.stringify(finalResponsesArray));
        localStorage.setItem('assessmentFinalTimestamp', new Date().toISOString());
        
        console.log('Stored final responses in local storage:', finalResponsesArray);
      } catch (err) {
        console.error('Failed to store final responses in local storage:', err);
      }
      
      // Save responses with completion flag
      const result = await saveResponses(true);
      
      // Debug check that we have a valid session ID
      if (!sessionId && !result?.sessionId) {
        console.error('No session ID available for redirection');
        setError('Error: No session ID available. Please try again.');
        return;
      }
      
      const finalSessionId = result?.sessionId || sessionId;
      console.log(`Redirecting to results page with sessionId=${finalSessionId}`);
      
      // Also store the assessment ID if available 
      const assessmentId = result?.data?.assessment_id;
      if (assessmentId) {
        console.log(`Storing assessment ID: ${assessmentId}`);
        localStorage.setItem('assessmentId', assessmentId);
      } else {
        console.warn('No assessment ID received from server');
      }
      
      // Store session ID with responses
      localStorage.setItem('assessmentSessionId', finalSessionId || '');
      
      // Include both session ID and assessment ID in the URL if available
      if (assessmentId) {
        navigate(`/results?sessionId=${finalSessionId}&assessmentId=${assessmentId}`);
      } else {
        navigate(`/results?sessionId=${finalSessionId}`);
      }
    } catch (err) {
      // Error is set by saveResponses
      console.error('Error during submission:', err);
    }
  };

  // Render loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Render error state
  if (error && !currentQuestion) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
        {error}
      </div>
    );
  }

  // Render empty state if no questions
  if (visibleQuestions.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-600">No assessment questions available.</p>
      </div>
    );
  }

  // Render current question
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
      <div className="mb-4 text-sm text-gray-500">
        Question {currentStep + 1} of {visibleQuestions.length}
      </div>
      
      <h3 className="text-xl font-semibold mb-4">{currentQuestion.text}</h3>
      
      {currentQuestion.guidance_text && (
        <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
          <p className="text-blue-700">{currentQuestion.guidance_text}</p>
        </div>
      )}
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}
      
      <div className="mb-6">
        {/* Yes/No Question */}
        {currentQuestion.type === 'yes_no' && (
          <div className="flex space-x-4">
            <button
              className={`px-6 py-2 border rounded-lg ${
                responses[currentQuestion.question_id] === 'Yes'
                  ? 'bg-blue-500 text-white border-blue-500'
                  : 'bg-white text-gray-700 border-gray-300'
              }`}
              onClick={() => handleResponseChange(currentQuestion.question_id, 'Yes')}
            >
              Yes
            </button>
            <button
              className={`px-6 py-2 border rounded-lg ${
                responses[currentQuestion.question_id] === 'No'
                  ? 'bg-blue-500 text-white border-blue-500'
                  : 'bg-white text-gray-700 border-gray-300'
              }`}
              onClick={() => handleResponseChange(currentQuestion.question_id, 'No')}
            >
              No
            </button>
            <button
              className={`px-6 py-2 border rounded-lg ${
                responses[currentQuestion.question_id] === 'NotSure'
                  ? 'bg-blue-500 text-white border-blue-500'
                  : 'bg-white text-gray-700 border-gray-300'
              }`}
              onClick={() => handleResponseChange(currentQuestion.question_id, 'NotSure')}
            >
              Not Sure
            </button>
          </div>
        )}
        
        {/* Text Question */}
        {currentQuestion.type === 'text' && (
          <textarea
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={responses[currentQuestion.question_id] as string || ''}
            onChange={(e) => handleResponseChange(currentQuestion.question_id, e.target.value)}
            rows={4}
            placeholder="Enter your response here..."
          />
        )}
        
        {/* Number Question */}
        {currentQuestion.type === 'number' && (
          <input
            type="number"
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={responses[currentQuestion.question_id] as string || ''}
            onChange={(e) => handleResponseChange(currentQuestion.question_id, e.target.value)}
            placeholder="Enter a number..."
          />
        )}
        
        {/* Dropdown Question */}
        {currentQuestion.type === 'dropdown' && currentQuestion.options && (
          <select
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={responses[currentQuestion.question_id] as string || ''}
            onChange={(e) => handleResponseChange(currentQuestion.question_id, e.target.value)}
          >
            <option value="">Select an option...</option>
            {currentQuestion.options.map((option) => (
              <option key={option.option_value} value={option.option_value}>
                {option.option_text}
              </option>
            ))}
          </select>
        )}
        
        {/* Multiple Choice Question */}
        {currentQuestion.type === 'multiple_choice' && currentQuestion.options && (
          <div className="space-y-2">
            {currentQuestion.options.map((option) => (
              <div key={option.option_value} className="flex items-center">
                <input
                  type="checkbox"
                  id={option.option_value}
                  className="mr-2 h-5 w-5"
                  checked={Array.isArray(responses[currentQuestion.question_id]) && 
                          (responses[currentQuestion.question_id] as string[]).includes(option.option_value)}
                  onChange={(e) => {
                    const currentValues = 
                      Array.isArray(responses[currentQuestion.question_id]) 
                        ? [...responses[currentQuestion.question_id] as string[]] 
                        : [];
                    
                    if (e.target.checked) {
                      handleResponseChange(
                        currentQuestion.question_id,
                        [...currentValues, option.option_value]
                      );
                    } else {
                      handleResponseChange(
                        currentQuestion.question_id,
                        currentValues.filter(v => v !== option.option_value)
                      );
                    }
                  }}
                />
                <label htmlFor={option.option_value}>
                  {option.option_text}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
      
      <div className="flex justify-between">
        <button
          className="px-6 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50"
          onClick={handlePrevious}
          disabled={currentStep === 0 || isSubmitting}
        >
          Previous
        </button>
        
        {currentStep < visibleQuestions.length - 1 ? (
          <button
            className="px-6 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50"
            onClick={handleNext}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Next'}
          </button>
        ) : (
          <button
            className="px-6 py-2 bg-green-500 text-white rounded-lg disabled:opacity-50"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Assessment'}
          </button>
        )}
      </div>
    </div>
  );
};

export default AssessmentQuestionnaire;
