import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { 
  getApplicantInfo, 
  createOrUpdateApplicant, 
  ApplicantFormData 
} from '../services/applicantService';
import { FormikHelpers } from 'formik';
import { AuthContext } from '../context/AuthContext';
// Import decorative elements
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';

// Validation schema
const ApplicantSchema = Yup.object().shape({
  organisation_name: Yup.string().required('Organization name is required'),
  address: Yup.string().required('Address is required'),
  industry_sector: Yup.string().required('Industry sector is required'),
  contact_name: Yup.string().required('Contact name is required'),
  contact_email: Yup.string().email('Invalid email').required('Contact email is required'),
  contact_telephone: Yup.string().required('Contact telephone is required')
});

const ApplicantForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<ApplicantFormData>({
    organisation_name: '',
    address: '',
    industry_sector: '',
    contact_name: '',
    contact_email: '',
    contact_telephone: '',
    organisation_role: 'End User',
    ai_system_overview: ''
  });

  // Check if user just registered
  const isNewRegistration = () => {
    // Look for a query parameter or state indicating new registration
    const searchParams = new URLSearchParams(location.search);
    const newRegistration = searchParams.get('new') === 'true' || 
                           (location.state && (location.state as any).newRegistration);
    
    // Also check localStorage for a recent registration flag
    const recentRegistration = localStorage.getItem('recent_registration') === 'true';
    
    return newRegistration || recentRegistration;
  };

  // Fetch existing applicant data if available
  useEffect(() => {
    const fetchApplicantData = async () => {
      try {
        setLoading(true);
        
        // Check if this is a new registration
        const newUser = isNewRegistration();
        if (newUser) {
          // Clear any existing form data from localStorage
          console.log('New registration detected - clearing previous form data');
          localStorage.removeItem('applicant_form_data');
          localStorage.removeItem('recent_registration');
          
          // Use AuthContext user data to pre-populate contact info if available
          if (user) {
            setInitialValues({
              ...initialValues,
              contact_name: user.name || '',
              contact_email: user.email || ''
            });
          } else {
            // Fall back to localStorage if AuthContext doesn't have the user (unlikely)
            const userString = localStorage.getItem('user');
            if (userString) {
              try {
                const userData = JSON.parse(userString);
                setInitialValues({
                  ...initialValues,
                  contact_name: userData.name || '',
                  contact_email: userData.email || ''
                });
              } catch (e) {
                console.error('Error parsing user data:', e);
              }
            }
          }
          
          // Skip fetching previous applicant data
          setLoading(false);
          return;
        }
        
        // If not a new user, fetch existing applicant data
        const response = await getApplicantInfo();
        if (response.success && response.data) {
          // If we have existing data but the user is logged in, ensure contact details match user profile
          if (user) {
            setInitialValues({
              ...response.data,
              contact_name: user.name || response.data.contact_name,
              contact_email: user.email || response.data.contact_email
            });
          } else {
            setInitialValues(response.data);
          }
        } else if (user) {
          // No existing data but user is logged in, use their profile info
          setInitialValues({
            ...initialValues,
            contact_name: user.name || '',
            contact_email: user.email || ''
          });
        }
      } catch (err: any) {
        // If 404, it means no data exists yet, which is fine
        if (err.response && err.response.status !== 404) {
          setError('Failed to load applicant data. Please try again.');
        } else if (user) {
          // If 404 but user is logged in, use their profile info
          setInitialValues({
            ...initialValues,
            contact_name: user.name || '',
            contact_email: user.email || ''
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchApplicantData();
  }, [user]);

  // Industry sectors options
  const industrySectors = [
    'Financial Services',
    'Healthcare',
    'Technology',
    'Education',
    'Retail',
    'Manufacturing',
    'Government',
    'Legal Services',
    'Transportation',
    'Energy',
    'Other',
  ];

  const onSubmit = async (values: ApplicantFormData, { setSubmitting }: FormikHelpers<ApplicantFormData>) => {
    try {
      // Add user_id from context or localStorage
      let userId = user?._id;
      let privacyTermsAccepted = user?.privacy_terms_accepted || false;
      
      // Fallback to localStorage if not available in context
      if (!userId) {
        const userString = localStorage.getItem('user');
        if (userString) {
          try {
            const userData = JSON.parse(userString);
            userId = userData._id || userData.id;
            privacyTermsAccepted = userData.privacy_terms_accepted || false;
          } catch (e) {
            console.error('Error parsing user data:', e);
          }
        }
      }
      
      // Include user_id and privacy_terms_accepted in the form data
      const formDataWithUser = {
        ...values,
        user_id: userId,
        privacy_terms_accepted: privacyTermsAccepted
      };
      
      // Log the complete form data being submitted
      console.log('Submitting form data with privacy terms:', formDataWithUser);
      
      // Save form data to localStorage
      localStorage.setItem('applicant_form_data', JSON.stringify(formDataWithUser));
      
      await createOrUpdateApplicant(formDataWithUser);
      navigate('/assessment');
    } catch (error: any) {
      // Enhanced error handling to show more detailed messages from the API
      if (error.response && error.response.data && error.response.data.error) {
        const errorDetails = error.response.data.details ? 
                            ` (${error.response.data.details})` : '';
        setError(`${error.response.data.error}${errorDetails}`);
      } else {
        setError('Failed to save applicant data. Please try again.');
      }
      console.error('Form submission error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-5xl mx-auto relative">
        {/* Decorative elements */}
        <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
          <img src={asset7} alt="" className="w-full h-full" />
        </div>
        <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
          <img src={asset8} alt="" className="w-full h-full" />
        </div>
        
        <div className="relative z-10">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto relative">
      {/* Decorative elements */}
      <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Applicant Information
        </h1>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
            {error}
          </div>
        )}

        <div className="bg-white rounded-lg shadow-lg p-8">
          <Formik
            initialValues={initialValues}
            validationSchema={ApplicantSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({ isSubmitting, status }) => (
              <Form className="space-y-8">
                {status && (
                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                    {status}
                  </div>
                )}

                {/* Organization Information Section */}
                <div className="border-b border-gray-200 pb-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Organization Information
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="organisation_name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Organization Name *
                      </label>
                      <Field
                        type="text"
                        name="organisation_name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="organisation_name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="industry_sector"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Industry Sector *
                      </label>
                      <Field
                        as="select"
                        name="industry_sector"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select Industry Sector</option>
                        {industrySectors.map((sector) => (
                          <option key={sector} value={sector}>
                            {sector}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="industry_sector"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Address *
                      </label>
                      <Field
                        as="textarea"
                        name="address"
                        rows={3}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>

                {/* Contact Information Section */}
                <div className="border-b border-gray-200 pb-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Contact Information
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="contact_name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Contact Name * {user && <span className="text-blue-600">(Auto-filled from your account)</span>}
                      </label>
                      <Field
                        type="text"
                        name="contact_name"
                        className={`w-full px-4 py-2 border border-gray-300 rounded-md ${user ? 'bg-gray-100' : 'focus:ring-blue-500 focus:border-blue-500'}`}
                        readOnly={!!user} 
                      />
                      <ErrorMessage
                        name="contact_name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="contact_email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Contact Email * {user && <span className="text-blue-600">(Auto-filled from your account)</span>}
                      </label>
                      <Field
                        type="email"
                        name="contact_email"
                        className={`w-full px-4 py-2 border border-gray-300 rounded-md ${user ? 'bg-gray-100' : 'focus:ring-blue-500 focus:border-blue-500'}`}
                        readOnly={!!user}
                      />
                      <ErrorMessage
                        name="contact_email"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="contact_telephone"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Contact Telephone *
                      </label>
                      <Field
                        type="text"
                        name="contact_telephone"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="contact_telephone"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 disabled:opacity-50"
                  >
                    {isSubmitting ? 'Saving...' : 'Save and Continue'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ApplicantForm; 