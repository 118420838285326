import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';

// Validation schema
const AuditSchema = Yup.object().shape({
  transparency_statement: Yup.string().required('This field is required'),
  ai_register: Yup.string().required('This field is required'),
  risk_assessment: Yup.string().required('This field is required'),
  human_oversight: Yup.string().required('This field is required'),
  data_governance: Yup.string().required('This field is required'),
  additional_documentation: Yup.string()
});

interface AuditFormData {
  transparency_statement: string;
  ai_register: string;
  risk_assessment: string;
  human_oversight: string;
  data_governance: string;
  additional_documentation: string;
}

const AuditQuestionsScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sessionId, setSessionId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [savedData, setSavedData] = useState<AuditFormData | null>(null);

  useEffect(() => {
    // Get session ID from query params or localStorage
    const searchParams = new URLSearchParams(location.search);
    const sid = searchParams.get('sessionId') || localStorage.getItem('assessmentSessionId') || '';
    
    if (!sid) {
      setError('No assessment session ID found. Please complete the previous steps first.');
      return;
    }
    
    setSessionId(sid);
    
    // Check if we have saved audit data in localStorage
    const savedAuditData = localStorage.getItem(`audit_data_${sid}`);
    if (savedAuditData) {
      try {
        const parsedData = JSON.parse(savedAuditData);
        setSavedData(parsedData);
      } catch (err) {
        console.error('Error parsing saved audit data:', err);
      }
    }
  }, [location.search]);

  const initialValues: AuditFormData = savedData || {
    transparency_statement: '',
    ai_register: '',
    risk_assessment: '',
    human_oversight: '',
    data_governance: '',
    additional_documentation: ''
  };

  const handleSaveForLater = (values: AuditFormData) => {
    try {
      // Save to localStorage
      localStorage.setItem(`audit_data_${sessionId}`, JSON.stringify(values));
      alert('Your progress has been saved. You can return to complete it later.');
    } catch (err) {
      console.error('Error saving audit data:', err);
      setError('Failed to save your progress. Please try again.');
    }
  };

  const handleSubmit = async (values: AuditFormData, { setSubmitting }: any) => {
    try {
      // Save to localStorage
      localStorage.setItem(`audit_data_${sessionId}`, JSON.stringify(values));
      
      // Store in localStorage for the final report
      localStorage.setItem('audit_data_final', JSON.stringify(values));
      
      // Navigate to the summary page
      navigate(`/assessment-summary?sessionId=${sessionId}`);
    } catch (err: any) {
      setError(err.message || 'An error occurred while submitting the audit data');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto relative">
      {/* Decorative elements */}
      <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Audit Questions
        </h1>

        {/* Progress indicator */}
        <div className="mb-8">
          <div className="flex justify-between mb-2">
            <div>Registration</div>
            <div>Qualification</div>
            <div>Compliance</div>
            <div className="font-bold">Audit</div>
            <div>Summary</div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '80%' }}></div>
          </div>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
            {error}
          </div>
        )}

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="text-blue-700">
              <strong>Important:</strong> This section assesses your AI system's transparency and accountability measures. 
              Please provide accurate information about your current practices.
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={AuditSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values }) => (
              <Form className="space-y-8">
                {/* Transparency Statement */}
                <div className="border-b border-gray-200 pb-6">
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Do you provide clear and easily accessible statements explaining the use and implications of your AI system? *
                  </label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="transparency_statement"
                        id="transparency_yes"
                        value="Yes"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="transparency_yes">Yes</label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="transparency_statement"
                        id="transparency_no"
                        value="No"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="transparency_no">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="transparency_statement"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* AI Register */}
                <div className="border-b border-gray-200 pb-6">
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Do you maintain a register of AI Systems that operate autonomously? *
                  </label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="ai_register"
                        id="ai_register_yes"
                        value="Yes"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="ai_register_yes">Yes</label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="ai_register"
                        id="ai_register_no"
                        value="No"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="ai_register_no">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="ai_register"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Risk Assessment */}
                <div className="border-b border-gray-200 pb-6">
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Have you conducted a risk assessment for your AI system? *
                  </label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="risk_assessment"
                        id="risk_assessment_yes"
                        value="Yes"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="risk_assessment_yes">Yes</label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="risk_assessment"
                        id="risk_assessment_no"
                        value="No"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="risk_assessment_no">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="risk_assessment"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Human Oversight */}
                <div className="border-b border-gray-200 pb-6">
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Do you have human oversight measures in place for your AI system? *
                  </label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="human_oversight"
                        id="human_oversight_yes"
                        value="Yes"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="human_oversight_yes">Yes</label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="human_oversight"
                        id="human_oversight_no"
                        value="No"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="human_oversight_no">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="human_oversight"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Data Governance */}
                <div className="border-b border-gray-200 pb-6">
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Do you have data governance policies for your AI system? *
                  </label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="data_governance"
                        id="data_governance_yes"
                        value="Yes"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="data_governance_yes">Yes</label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="data_governance"
                        id="data_governance_no"
                        value="No"
                        className="mr-2 h-4 w-4"
                      />
                      <label htmlFor="data_governance_no">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="data_governance"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Additional Documentation */}
                <div>
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Additional Documentation or Comments (Optional)
                  </label>
                  <Field
                    as="textarea"
                    name="additional_documentation"
                    rows={4}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Provide any additional information or documentation that may be relevant"
                  />
                </div>

                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => navigate(`/compliance-assessment?sessionId=${sessionId}`)}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded-lg transition duration-300"
                  >
                    Back
                  </button>
                  
                  <div className="space-x-4">
                    <button
                      type="button"
                      onClick={() => handleSaveForLater(values)}
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
                    >
                      Save for Later
                    </button>
                    
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 disabled:opacity-50"
                    >
                      {isSubmitting ? 'Submitting...' : 'Continue to Summary'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AuditQuestionsScreen;
