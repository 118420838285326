import React from 'react';

interface ContactButtonProps {
  onClick?: () => void;
  className?: string;
}

const ContactButton: React.FC<ContactButtonProps> = ({ 
  onClick, 
  className = "bg-yellow-600 hover:bg-yellow-700 text-white font-medium py-3 px-6 rounded-md"
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      // Default action: Send email
      window.location.href = 'mailto:contact@whitelabelconsultancy.com';
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex items-center ${className}`}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        className="h-5 w-5 mr-2" 
        fill="none" 
        viewBox="0 0 24 24" 
        stroke="currentColor"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" 
        />
      </svg>
      Contact Us For Assistance
    </button>
  );
};

export default ContactButton;
