import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../context/AuthContext';
// Import the CSS for decorative elements and the logo
import '../styles/decorations.css';
import logo from '../assets/images/WL-logo-dark-blue.png';

// Validation schema
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const Login: React.FC = () => {
  const { login, user, error, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formError, setFormError] = useState<string | null>(null);

  // Redirect if already logged in
  useEffect(() => {
    if (user) {
      navigate('/applicant-form');
    }
  }, [user, navigate]);

  // Update form error when context error changes
  useEffect(() => {
    setFormError(error);
  }, [error]);

  return (
    <div className="max-w-md mx-auto relative">
      {/* Decorative elements */}
      <div className="auth-decoration"></div>
      
      <div className="relative z-10">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="White Label Consultancy" className="h-16" />
        </div>
        
        <h1 className="text-3xl font-headlines font-bold text-center text-brand-dark-blue mb-8">
          Login to Your Account
        </h1>

        <div className="bg-white rounded-lg shadow-lg p-8">
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setFormError(null);
              await login(values.email, values.password);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-6">
                {formError && (
                  <div className="bg-red-100 border border-brand-red text-brand-red px-4 py-3 rounded">
                    {formError}
                  </div>
                )}

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email Address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-brand-light-blue focus:border-brand-light-blue"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-brand-red text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Password
                  </label>
                  <Field
                    type="password"
                    name="password"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-brand-light-blue focus:border-brand-light-blue"
                    placeholder="Enter your password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-brand-red text-sm mt-1"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting || loading}
                  className="w-full bg-brand-light-blue hover:bg-brand-very-light-blue text-brand-dark-blue font-bold py-2 px-4 rounded-md transition-colors duration-300 disabled:opacity-50"
                >
                  {loading ? 'Logging in...' : 'Login'}
                </button>
              </Form>
            )}
          </Formik>

          <div className="mt-6 text-center">
            <p className="text-gray-600">
              Don't have an account?{' '}
              <Link to="/register" className="text-brand-light-blue hover:text-brand-medium-blue">
                Register here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login; 