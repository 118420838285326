import axios from 'axios';

// API Endpoints
const ADMIN_APPROVAL_URL = '/api/admin-approval.php';
const EXTENDED_ASSESSMENT_URL = '/api/extended-assessment.php';

// Get token from local storage
const getToken = (): string | null => {
  const user = localStorage.getItem('user');
  if (user) {
    try {
      const userData = JSON.parse(user);
      return userData.token;
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  }
  return null;
};

// Configure axios with auth header
const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Types
export interface AdminApprovalStatus {
  approval_status: 'not_requested' | 'pending' | 'approved' | 'rejected';
  payment_verified: boolean;
  assessment_completed: boolean;
  approved_at?: string;
}

export interface ExtendedAssessmentQuestion {
  id: number;
  question_id: string;
  section: string;
  text: string;
  type: 'yes_no' | 'text' | 'file_upload' | 'url' | 'checkbox';
  order_num: number;
  is_required: boolean;
  parent_question_id?: string;
  parent_answer?: string;
  guidance_text?: string;
  children?: ExtendedAssessmentQuestion[];
}

export interface ExtendedAssessmentResponse {
  questionId: string;
  value: string | string[];
  filePath?: string;
}

export interface ExtendedAssessmentSectionMap {
  [key: string]: string;
}

// API Functions
export const getAdminApprovalStatus = async (): Promise<AdminApprovalStatus> => {
  try {
    const response = await axiosInstance.get(ADMIN_APPROVAL_URL);
    return response.data.data;
  } catch (error) {
    console.error('Error getting admin approval status:', error);
    throw error;
  }
};

export const requestAdminApproval = async (): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await axiosInstance.post(ADMIN_APPROVAL_URL);
    return response.data;
  } catch (error) {
    console.error('Error requesting admin approval:', error);
    throw error;
  }
};

export const getExtendedAssessmentQuestions = async (): Promise<{
  questions: ExtendedAssessmentQuestion[];
  responses: { [key: string]: { value: string; file_path?: string } };
  sections: ExtendedAssessmentSectionMap;
}> => {
  try {
    const response = await axiosInstance.get(EXTENDED_ASSESSMENT_URL);
    return response.data.data;
  } catch (error) {
    console.error('Error getting extended assessment questions:', error);
    throw error;
  }
};

export const saveExtendedAssessmentResponses = async (
  responses: ExtendedAssessmentResponse[]
): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await axiosInstance.post(EXTENDED_ASSESSMENT_URL, {
      responses
    });
    return response.data;
  } catch (error) {
    console.error('Error saving extended assessment responses:', error);
    throw error;
  }
};

export const uploadFile = async (
  file: File,
  questionId: string
): Promise<{ success: boolean; message: string; data?: { filePath: string } }> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('questionId', questionId);

    const response = await axios.post(EXTENDED_ASSESSMENT_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

