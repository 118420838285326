import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
// Import the decorations CSS
import '../styles/decorations.css';
// Import the logo
import logo from '../assets/images/WL-logo-white.png';
// Import Asset 7 directly
import asset7 from '../assets/images/Asset 7 copy.svg';

const Header: React.FC = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="bg-brand-dark-blue text-white shadow-md relative overflow-hidden">
      {/* Background decorative element - concentric circles */}
      <div className="absolute -right-16 -bottom-16 opacity-10 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      
      <div className="container mx-auto px-4 py-4 relative z-10">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            {/* Use the logo with proper dimensions */}
            <img src={logo} alt="White Label Consultancy" className="h-10 w-auto" />
          </Link>

          {/* Mobile menu button */}
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>

          {/* Desktop navigation */}
          <nav className="hidden md:flex space-x-6">
            <Link to="/" className="hover:text-brand-very-light-blue">
              Home
            </Link>
            {user ? (
              <>
                <Link to="/applicant-form" className="hover:text-brand-very-light-blue">
                  Applicant Form
                </Link>
                <Link to="/assessment" className="hover:text-brand-very-light-blue">
                  Assessment
                </Link>
                <Link to="/extended-assessment-access" className="hover:text-brand-very-light-blue">
                  Extended Assessment
                </Link>
                <button
                  onClick={handleLogout}
                  className="hover:text-brand-very-light-blue"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="hover:text-brand-very-light-blue transition-colors duration-200">
                  Login
                </Link>
                <Link 
                  to="/register" 
                  className="bg-brand-light-blue text-brand-dark-blue font-medium px-4 py-2 rounded-md hover:bg-brand-very-light-blue transition-colors duration-200"
                >
                  Register
                </Link>
              </>
            )}
          </nav>
        </div>

        {/* Mobile navigation */}
        {isMenuOpen && (
          <nav className="mt-4 md:hidden flex flex-col space-y-3">
            <Link
              to="/"
              className="hover:text-brand-very-light-blue"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </Link>
            {user ? (
              <>
                <Link
                  to="/applicant-form"
                  className="hover:text-brand-very-light-blue"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Applicant Form
                </Link>
                <Link
                  to="/assessment"
                  className="hover:text-brand-very-light-blue"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Assessment
                </Link>
                <Link
                  to="/extended-assessment-access"
                  className="hover:text-brand-very-light-blue"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Extended Assessment
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMenuOpen(false);
                  }}
                  className="hover:text-brand-very-light-blue text-left"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="hover:text-brand-very-light-blue"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="bg-brand-light-blue text-brand-dark-blue px-4 py-2 rounded-md hover:bg-brand-very-light-blue inline-block"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Register
                </Link>
              </>
            )}
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header; 