import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/images/WL-logo-dark-blue.png';

interface ReportGeneratorProps {
  registrationData: any;
  assessmentResults: any;
  auditData: any;
  sessionId: string;
}

const ReportGenerator: React.FC<ReportGeneratorProps> = ({
  registrationData,
  assessmentResults,
  auditData,
  sessionId
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [reportUrl, setReportUrl] = useState<string | null>(null);

  // Generate PDF when component mounts
  useEffect(() => {
    generatePDF();
  }, []);

  const generatePDF = async () => {
    setIsGenerating(true);
    
    try {
      // Create a new PDF document
      const doc = new jsPDF() as any;
      
      // Add logo
      const img = new Image();
      img.src = logo;
      await new Promise((resolve) => {
        img.onload = resolve;
      });
      
      // Add logo to the PDF
      doc.addImage(img, 'PNG', 10, 10, 40, 20);
      
      // Add title
      doc.setFontSize(20);
      doc.setTextColor(0, 51, 102); // Dark blue color
      doc.text('DIFC AI Certification Assessment Report', 105, 40, { align: 'center' });
      
      // Add date
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100); // Gray color
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 105, 48, { align: 'center' });
      doc.text(`Session ID: ${sessionId}`, 105, 53, { align: 'center' });
      
      // Add horizontal line
      doc.setDrawColor(0, 51, 102); // Dark blue color
      doc.setLineWidth(0.5);
      doc.line(20, 60, 190, 60);
      
      let yPos = 70;
      
      // Add registration information
      if (registrationData) {
        doc.setFontSize(16);
        doc.setTextColor(0, 51, 102); // Dark blue color
        doc.text('Registration Information', 20, yPos);
        yPos += 10;
        
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0); // Black color
        
        // Organization information
        doc.setFontSize(12);
        doc.setTextColor(0, 102, 204); // Blue color
        doc.text('Organization Details', 20, yPos);
        yPos += 7;
        
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0); // Black color
        doc.text(`Organization Name: ${registrationData.organisation_name}`, 25, yPos);
        yPos += 7;
        doc.text(`Industry Sector: ${registrationData.industry_sector}`, 25, yPos);
        yPos += 7;
        
        // Address might be long, so split it into multiple lines
        const addressLines = doc.splitTextToSize(`Address: ${registrationData.organisation_address}`, 160);
        doc.text(addressLines, 25, yPos);
        yPos += addressLines.length * 7 + 5;
        
        // User information
        doc.setFontSize(12);
        doc.setTextColor(0, 102, 204); // Blue color
        doc.text('Contact Information', 20, yPos);
        yPos += 7;
        
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0); // Black color
        doc.text(`Name: ${registrationData.user_name}`, 25, yPos);
        yPos += 7;
        doc.text(`Email: ${registrationData.user_email}`, 25, yPos);
        yPos += 15;
      }
      
      // Add assessment results
      if (assessmentResults) {
        // Check if we need to add a new page
        if (yPos > 230) {
          doc.addPage();
          yPos = 20;
        }
        
        doc.setFontSize(16);
        doc.setTextColor(0, 51, 102); // Dark blue color
        doc.text('Assessment Results', 20, yPos);
        yPos += 10;
        
        // Assessment outcome
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0); // Black color
        
        const requiresCertification = assessmentResults.requires_certification;
        const statusText = requiresCertification ? 'Certification Required' : 'Certification Not Required';
        
        if (requiresCertification) {
          doc.setTextColor(204, 0, 0); // Red
        } else {
          doc.setTextColor(0, 153, 0); // Green
        }
        doc.text(`Assessment Result: ${statusText}`, 25, yPos);
        yPos += 10;
        
        // Summary
        doc.setTextColor(0, 0, 0); // Black color
        doc.setFontSize(12);
        doc.text('Summary:', 25, yPos);
        yPos += 7;
        
        const summaryLines = doc.splitTextToSize(assessmentResults.summary, 160);
        doc.setFontSize(11);
        doc.text(summaryLines, 30, yPos);
        yPos += summaryLines.length * 7 + 5;
        
        // Certification reasons
        if (assessmentResults.certification_reasons && assessmentResults.certification_reasons.length > 0) {
          doc.setFontSize(12);
          doc.text('Key Findings:', 25, yPos);
          yPos += 7;
          
          doc.setFontSize(11);
          assessmentResults.certification_reasons.forEach((reason: string, index: number) => {
            const reasonText = `${index + 1}. ${reason}`;
            const reasonLines = doc.splitTextToSize(reasonText, 155);
            doc.text(reasonLines, 30, yPos);
            yPos += reasonLines.length * 7 + 3;
          });
          
          yPos += 5;
        }
        
        // Responses
        if (assessmentResults.responses && assessmentResults.responses.length > 0) {
          // Check if we need to add a new page
          if (yPos > 200) {
            doc.addPage();
            yPos = 20;
          }
          
          doc.setFontSize(12);
          doc.text('Your Responses:', 25, yPos);
          yPos += 7;
          
          const tableData = assessmentResults.responses.map((response: any) => [
            response.question_text,
            Array.isArray(response.value) ? response.value.join(', ') : response.value
          ]);
          
          doc.autoTable({
            startY: yPos,
            head: [['Question', 'Your Response']],
            body: tableData,
            headStyles: { fillColor: [0, 51, 102] },
            columnStyles: {
              0: { cellWidth: 110 },
              1: { cellWidth: 70 }
            },
            margin: { left: 25 }
          });
          
          yPos = (doc as any).lastAutoTable.finalY + 15;
        }
      }
      
      // Add audit information
      if (auditData) {
        // Check if we need to add a new page
        if (yPos > 230) {
          doc.addPage();
          yPos = 20;
        }
        
        doc.setFontSize(16);
        doc.setTextColor(0, 51, 102); // Dark blue color
        doc.text('Audit Information', 20, yPos);
        yPos += 10;
        
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0); // Black color
        
        const auditQuestions = [
          { question: 'Do you provide clear and easily accessible statements explaining the use and implications of your AI system?', answer: auditData.transparency_statement },
          { question: 'Do you maintain a register of AI Systems that operate autonomously?', answer: auditData.ai_register },
          { question: 'Have you conducted a risk assessment for your AI system?', answer: auditData.risk_assessment },
          { question: 'Do you have human oversight measures in place for your AI system?', answer: auditData.human_oversight },
          { question: 'Do you have data governance policies for your AI system?', answer: auditData.data_governance }
        ];
        
        const tableData = auditQuestions.map((item: any) => [item.question, item.answer]);
        
        doc.autoTable({
          startY: yPos,
          head: [['Question', 'Your Response']],
          body: tableData,
          headStyles: { fillColor: [0, 51, 102] },
          columnStyles: {
            0: { cellWidth: 110 },
            1: { cellWidth: 70 }
          },
          margin: { left: 25 }
        });
        
        yPos = (doc as any).lastAutoTable.finalY + 10;
        
        // Additional documentation
        if (auditData.additional_documentation) {
          // Check if we need to add a new page
          if (yPos > 230) {
            doc.addPage();
            yPos = 20;
          }
          
          doc.setFontSize(12);
          doc.text('Additional Documentation:', 25, yPos);
          yPos += 7;
          
          const docLines = doc.splitTextToSize(auditData.additional_documentation, 160);
          doc.setFontSize(11);
          doc.text(docLines, 30, yPos);
          yPos += docLines.length * 7 + 10;
        }
      }
      
      // Add next steps
      // Check if we need to add a new page
      if (yPos > 200) {
        doc.addPage();
        yPos = 20;
      }
      
      doc.setFontSize(16);
      doc.setTextColor(0, 51, 102); // Dark blue color
      doc.text('Next Steps', 20, yPos);
      yPos += 10;
      
      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0); // Black color
      
      const requiresCertification = assessmentResults?.requires_certification;
      
      if (requiresCertification) {
        const nextSteps = [
          'Contact an Accredited Certification Body to initiate the certification process.',
          'Prepare all necessary documentation for the certification review.',
          'Implement any required changes to your AI system based on the assessment.',
          'Schedule a formal evaluation with the Certification Body.',
          'Maintain ongoing compliance with DIFC Regulation 10 requirements.'
        ];
        
        nextSteps.forEach((step, index) => {
          doc.text(`${index + 1}. ${step}`, 25, yPos);
          yPos += 7;
        });
      } else {
        const nextSteps = [
          'Continue to monitor your AI system for changes that might affect certification requirements.',
          'Implement best practices for responsible AI development and deployment.',
          'Stay informed about updates to DIFC Regulation 10.',
          'Consider voluntary certification if your AI system\'s risk profile changes.'
        ];
        
        nextSteps.forEach((step, index) => {
          doc.text(`${index + 1}. ${step}`, 25, yPos);
          yPos += 7;
        });
      }
      
      // Add footer
      const pageCount = doc.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.setTextColor(100, 100, 100);
        doc.text(`Page ${i} of ${pageCount}`, 105, 285, { align: 'center' });
        doc.text('DIFC AI Certification Assessment Report', 105, 292, { align: 'center' });
      }
      
      // Generate blob URL for the PDF
      const pdfBlob = doc.output('blob');
      const url = URL.createObjectURL(pdfBlob);
      setReportUrl(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="mt-6">
      <h3 className="text-xl font-semibold mb-4">Assessment Report</h3>
      
      {isGenerating ? (
        <div className="flex items-center space-x-2">
          <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500"></div>
          <span>Generating report...</span>
        </div>
      ) : reportUrl ? (
        <div className="space-y-4">
          <div className="flex space-x-4">
            <a
              href={reportUrl}
              download={`DIFC_AI_Assessment_Report_${new Date().toISOString().split('T')[0]}.pdf`}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              Download Report
            </a>
            
            <a
              href={reportUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              View Report
            </a>
          </div>
          
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4">
            <p className="text-blue-700">
              <strong>Note:</strong> Your assessment report has been generated and is ready for download. 
              This report includes all the information you've provided and the assessment results.
            </p>
          </div>
        </div>
      ) : (
        <div className="bg-red-100 border-l-4 border-red-500 p-4">
          <p className="text-red-700">
            <strong>Error:</strong> Failed to generate the report. Please try again.
          </p>
        </div>
      )}
    </div>
  );
};

export default ReportGenerator;
