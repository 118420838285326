import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveComplianceData, submitComplianceAssessment, getComplianceData } from '../services/complianceService';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';

interface ComplianceFormData {
  aiSystemName: string;
  aiSystemVersion: string;
  aiSystemDescription: string;
  riskMitigationMeasures: string;
  dataGovernancePolicies: string;
  humanOversightMeasures: string;
  technicalDocumentation: string;
  additionalInformation: string;
}

const ComplianceAssessment: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string>('');
  const [formData, setFormData] = useState<ComplianceFormData>({
    aiSystemName: '',
    aiSystemVersion: '',
    aiSystemDescription: '',
    riskMitigationMeasures: '',
    dataGovernancePolicies: '',
    humanOversightMeasures: '',
    technicalDocumentation: '',
    additionalInformation: ''
  });

  useEffect(() => {
    const fetchComplianceData = async () => {
      // Get session ID from query params
      const searchParams = new URLSearchParams(location.search);
      const sid = searchParams.get('sessionId') || localStorage.getItem('assessmentSessionId') || '';
      
      if (!sid) {
        setError('No assessment session ID provided. Please complete the preliminary assessment first.');
        return;
      }
      
      setSessionId(sid);
      
      try {
        // Try to fetch compliance data from backend
        const data = await getComplianceData(sid);
        setFormData(data);
        setSuccess('Your previously saved data has been loaded.');
        setTimeout(() => setSuccess(null), 3000);
      } catch (err) {
        console.log('No existing compliance data found on server, checking localStorage...');
        
        // Check if we have saved form data in localStorage as fallback
        const savedFormData = localStorage.getItem(`compliance_form_${sid}`);
        if (savedFormData) {
          try {
            const parsedData = JSON.parse(savedFormData);
            setFormData(parsedData);
            setSuccess('Your previously saved data has been loaded from local storage.');
            setTimeout(() => setSuccess(null), 3000);
          } catch (err) {
            console.error('Error parsing saved form data:', err);
          }
        }
      }
    };
    
    fetchComplianceData();
  }, [location.search]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Save to backend and localStorage
      await saveComplianceData({
        sessionId,
        aiSystemName: formData.aiSystemName,
        aiSystemVersion: formData.aiSystemVersion,
        aiSystemDescription: formData.aiSystemDescription,
        riskMitigationMeasures: formData.riskMitigationMeasures,
        dataGovernancePolicies: formData.dataGovernancePolicies,
        humanOversightMeasures: formData.humanOversightMeasures,
        technicalDocumentation: formData.technicalDocumentation,
        additionalInformation: formData.additionalInformation
      });
      
      setSuccess('Your compliance assessment has been saved. You can return to complete it later.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err: any) {
      setError(err.message || 'Failed to save compliance assessment');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      
      // Validate required fields
      const requiredFields: (keyof ComplianceFormData)[] = [
        'aiSystemName', 
        'aiSystemVersion', 
        'aiSystemDescription',
        'riskMitigationMeasures'
      ];
      
      for (const field of requiredFields) {
        if (!formData[field]) {
          setError(`Please complete the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()} field`);
          setLoading(false);
          return;
        }
      }
      
      // Submit to backend
      await submitComplianceAssessment({
        sessionId,
        aiSystemName: formData.aiSystemName,
        aiSystemVersion: formData.aiSystemVersion,
        aiSystemDescription: formData.aiSystemDescription,
        riskMitigationMeasures: formData.riskMitigationMeasures,
        dataGovernancePolicies: formData.dataGovernancePolicies,
        humanOversightMeasures: formData.humanOversightMeasures,
        technicalDocumentation: formData.technicalDocumentation,
        additionalInformation: formData.additionalInformation
      });
      
      // Navigate to final report
      navigate(`/final-report?sessionId=${sessionId}`);
    } catch (err: any) {
      setError(err.message || 'Failed to submit compliance assessment');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto relative">
      <div className="absolute -right-16 top-20 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-20 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Compliance Assessment
        </h1>

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="text-blue-700">
              <strong>Important:</strong> Based on your preliminary assessment, your AI system requires certification under DIFC Regulation 10. Please provide detailed information about your AI system's compliance measures below.
            </p>
          </div>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
              {error}
            </div>
          )}

          {success && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6">
              {success}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                AI System Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="aiSystemName"
                value={formData.aiSystemName}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                placeholder="Enter the name of your AI system"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                AI System Version <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="aiSystemVersion"
                value={formData.aiSystemVersion}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                placeholder="Enter the version of your AI system"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                AI System Description <span className="text-red-500">*</span>
              </label>
              <textarea
                name="aiSystemDescription"
                value={formData.aiSystemDescription}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                rows={4}
                placeholder="Provide a detailed description of your AI system, including its purpose, functionality, and intended use cases"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Risk Mitigation Measures <span className="text-red-500">*</span>
              </label>
              <textarea
                name="riskMitigationMeasures"
                value={formData.riskMitigationMeasures}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                rows={4}
                placeholder="Describe the measures implemented to identify, assess, and mitigate risks associated with your AI system"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Data Governance Policies
              </label>
              <textarea
                name="dataGovernancePolicies"
                value={formData.dataGovernancePolicies}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                rows={4}
                placeholder="Describe your data governance policies, including data quality, privacy, and security measures"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Human Oversight Measures
              </label>
              <textarea
                name="humanOversightMeasures"
                value={formData.humanOversightMeasures}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                rows={4}
                placeholder="Describe the human oversight measures implemented for your AI system"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Technical Documentation
              </label>
              <textarea
                name="technicalDocumentation"
                value={formData.technicalDocumentation}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                rows={4}
                placeholder="Describe the technical documentation available for your AI system"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Additional Information
              </label>
              <textarea
                name="additionalInformation"
                value={formData.additionalInformation}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                rows={4}
                placeholder="Provide any additional information that may be relevant to the certification process"
              />
            </div>

            <div className="flex justify-between pt-4">
              <button
                type="button"
                onClick={() => navigate(`/qualification-decision?sessionId=${sessionId}`)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-3 px-8 rounded-lg transition duration-300"
              >
                Back
              </button>
              
              <div className="space-x-4">
                <button
                  type="button"
                  onClick={handleSave}
                  disabled={loading}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300 disabled:opacity-50"
                >
                  {loading ? 'Saving...' : 'Save for Later'}
                </button>
                
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300 disabled:opacity-50"
                >
                  {loading ? 'Submitting...' : 'Submit for Review'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ComplianceAssessment;
