import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAdminApprovalStatus, requestAdminApproval, AdminApprovalStatus } from '../../services/extendedAssessmentService';
import { AuthContext } from '../../context/AuthContext';

const ExtendedAssessmentAccess: React.FC = () => {
  const [approvalStatus, setApprovalStatus] = useState<AdminApprovalStatus | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [requestSubmitting, setRequestSubmitting] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApprovalStatus = async () => {
      try {
        setLoading(true);
        setError(null);
        const status = await getAdminApprovalStatus();
        setApprovalStatus(status);
      } catch (err: any) {
        setError(err.message || 'Failed to fetch approval status');
      } finally {
        setLoading(false);
      }
    };

    fetchApprovalStatus();
  }, []);

  const requestAccess = async () => {
    try {
      setRequestSubmitting(true);
      setError(null);
      await requestAdminApproval();
      // Refetch status after requesting
      const status = await getAdminApprovalStatus();
      setApprovalStatus(status);
    } catch (err: any) {
      setError(err.message || 'Failed to request access');
    } finally {
      setRequestSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="container mt-5">
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3">Checking access status...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
        <button 
          className="btn btn-primary" 
          onClick={() => window.location.reload()}
        >
          Try Again
        </button>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="container mt-5">
        <div className="card shadow-sm">
          <div className="card-body">
            <h2 className="card-title">Extended Assessment Access</h2>
            <p className="card-text">
              You need to be logged in to request access to the extended assessment.
            </p>
            <Link to="/login" className="btn btn-primary">
              Log In
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (approvalStatus?.assessment_completed === false) {
    return (
      <div className="container mt-5">
        <div className="card shadow-sm">
          <div className="card-body">
            <h2 className="card-title">Complete Preliminary Assessment First</h2>
            <p className="card-text">
              You need to complete the preliminary assessment before you can request access to the extended assessment.
            </p>
            <Link to="/assessment" className="btn btn-primary">
              Take Preliminary Assessment
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // User has been approved
  if (approvalStatus?.approval_status === 'approved') {
    return (
      <div className="container mt-5">
        <div className="card shadow-sm border-success">
          <div className="card-body">
            <h2 className="card-title text-success">Access Approved!</h2>
            <p className="card-text">
              Your request to access the extended assessment has been approved. You can now proceed to the assessment.
            </p>
            <Link to="/extended-assessment" className="btn btn-success">
              Start Extended Assessment
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // User request has been rejected
  if (approvalStatus?.approval_status === 'rejected') {
    return (
      <div className="container mt-5">
        <div className="card shadow-sm border-danger">
          <div className="card-body">
            <h2 className="card-title text-danger">Access Request Rejected</h2>
            <p className="card-text">
              Your request to access the extended assessment has been rejected. If you believe this is an error, please contact our support team.
            </p>
            <a href="mailto:support@example.com" className="btn btn-outline-primary">
              Contact Support
            </a>
          </div>
        </div>
      </div>
    );
  }

  // User request is pending
  if (approvalStatus?.approval_status === 'pending') {
    return (
      <div className="container mt-5">
        <div className="card shadow-sm border-warning">
          <div className="card-body">
            <h2 className="card-title text-warning">Access Request Pending</h2>
            <p className="card-text">
              Your request to access the extended assessment is currently being reviewed by our administrators. We'll notify you once a decision has been made.
            </p>
            <div className="d-flex gap-3 mt-3">
              <a href="mailto:support@example.com" className="btn btn-outline-primary">
                Contact Support
              </a>
              <button 
                className="btn btn-secondary" 
                onClick={() => window.location.reload()}
              >
                Check Status Again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // User has not requested access yet
  return (
    <div className="container mt-5">
      <div className="card shadow-sm">
        <div className="card-body">
          <h2 className="card-title">Extended Assessment Access</h2>
          <p className="card-text">
            The extended assessment provides a comprehensive evaluation of your case's eligibility for R&D tax credits.
            This detailed assessment requires admin approval before you can proceed.
          </p>
          <p className="card-text">
            Based on your preliminary assessment results, you may qualify for a more detailed analysis.
            Please request access below to continue with the extended assessment.
          </p>
          
          <div className="mt-4">
            <button 
              className="btn btn-primary" 
              onClick={requestAccess}
              disabled={requestSubmitting}
            >
              {requestSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Requesting...
                </>
              ) : 'Request Access'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendedAssessmentAccess;
