import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { conductAssessment } from '../services/applicantService';
import AssessmentQuestionnaire from '../components/AssessmentQuestionnaire';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';

const Assessment: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [startedAssessment, setStartedAssessment] = useState(false);

  const handleStartAssessment = () => {
    setStartedAssessment(true);
  };

  const handleAssessmentComplete = (sessionId: string) => {
    // Navigate to results page with the session ID
    navigate(`/results?sessionId=${sessionId}`);
  };

  return (
    <div className="max-w-4xl mx-auto relative">
      <div className="absolute -right-16 top-20 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-20 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Preliminary Assessment
        </h1>

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            About the Assessment
          </h2>
          <p className="text-gray-600 mb-4">
            Based on the information you've provided, we will conduct a preliminary
            assessment to determine if your AI system requires certification under
            DIFC Regulation 10.
          </p>
          <p className="text-gray-600 mb-4">
            The assessment will evaluate the following criteria:
          </p>
          <ul className="list-disc pl-6 mb-6 text-gray-600 space-y-2">
            <li>Whether your organization is registered in DIFC</li>
            <li>
              Whether your AI system targets individuals in DIFC or is deployed for
              use in DIFC
            </li>
            <li>
              Whether your AI system processes personal data in connection with
              high-risk activities
            </li>
          </ul>

          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="text-blue-700">
              <strong>Note:</strong> This is a preliminary assessment only. The
              final determination of certification requirements may involve
              additional factors and regulatory considerations.
            </p>
          </div>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
              {error}
            </div>
          )}

          {!startedAssessment ? (
            <div className="flex justify-center">
              <button
                onClick={handleStartAssessment}
                disabled={loading}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300 disabled:opacity-50"
              >
                Start Assessment Questionnaire
              </button>
            </div>
          ) : (
            <AssessmentQuestionnaire onComplete={handleAssessmentComplete} />
          )}
        </div>

        {!startedAssessment && (
          <div className="bg-gray-50 rounded-lg p-6 border border-gray-200">
            <h3 className="text-lg font-semibold text-gray-800 mb-3">
              What happens next?
            </h3>
            <p className="text-gray-600 mb-2">
              After the assessment, you will receive one of the following results:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-1">
              <li>
                <strong>Certification Required:</strong> Your AI system is subject to
                mandatory certification under DIFC Regulation 10.
              </li>
              <li>
                <strong>Certification Not Required:</strong> Your AI system is not
                subject to mandatory certification under DIFC Regulation 10.
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Assessment; 