import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
// Import the decorations CSS
import '../styles/decorations.css';
// Import logo and assets
import logo from '../assets/images/WL-logo-dark-blue.png';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';
import asset10 from '../assets/images/Asset 10 copy.svg';

const Home: React.FC = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="max-w-6xl mx-auto">
      {/* Hero Section with Image */}
      <div className="flex flex-col md:flex-row items-center justify-between mb-16 py-8">
        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
          <h1 className="text-4xl md:text-5xl font-headlines font-bold text-brand-dark-blue mb-4">
            DIFC AI Certification Platform
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Determine if your AI system requires certification under DIFC Regulation 10
          </p>
          <div className="space-y-4 md:space-y-0 md:space-x-4">
            {!user && (
              <>
                <Link
                  to="/register"
                  className="bg-brand-dark-blue hover:bg-brand-medium-blue text-white font-bold py-3 px-8 rounded-lg transition-colors duration-300 inline-block"
                >
                  Register
                </Link>
                <Link
                  to="/login"
                  className="bg-brand-dark-blue hover:bg-brand-medium-blue text-white font-bold py-3 px-8 rounded-lg transition-colors duration-300 inline-block"
                >
                  Login
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="md:w-1/2 flex justify-center relative">
          {/* Hero image with background decoration */}
          <div className="absolute w-full h-full flex items-center justify-center">
            <img src={asset10} alt="" className="w-full h-auto opacity-20" />
          </div>
          <div className="bg-brand-very-light-blue opacity-80 w-full h-64 md:h-96 rounded-lg shadow-lg flex items-center justify-center relative z-10">
            <img src={logo} alt="White Label Consultancy" className="w-1/2 max-w-xs" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-8 mb-12 relative overflow-hidden">
        {/* Background decorative element */}
        <div className="absolute -right-16 -bottom-16 opacity-5 w-64 h-64">
          <img src={asset7} alt="" className="w-full h-full" />
        </div>
        
        <div className="relative z-10">
          <h2 className="text-2xl font-headlines font-semibold text-brand-dark-blue mb-4">
            About DIFC Regulation 10
          </h2>
          <p className="text-gray-600 mb-4">
            The Dubai International Financial Centre (DIFC) has introduced Regulation 10, 
            which establishes a comprehensive framework for the certification of AI systems 
            used within or targeting individuals in the DIFC.
          </p>
          <p className="text-gray-600 mb-4">
            This platform guides you through a preliminary assessment to determine if your 
            AI system requires mandatory certification under this regulation.
          </p>
        </div>
      </div>

      <div className="bg-brand-very-light-blue rounded-lg shadow p-8 mb-12 relative overflow-hidden">
        {/* Background decorative element */}
        <div className="absolute -left-16 -top-16 opacity-10 w-64 h-64">
          <img src={asset8} alt="" className="w-full h-full" />
        </div>
        
        <div className="relative z-10">
          <h2 className="text-2xl font-headlines font-semibold text-brand-dark-blue mb-6 text-center">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105">
              <div className="flex justify-center mb-4">
                <div className="bg-brand-light-blue h-16 w-16 rounded-full flex items-center justify-center">
                  <span className="text-brand-dark-blue font-bold text-xl">1</span>
                </div>
              </div>
              <h3 className="font-semibold text-brand-dark-blue text-lg text-center mb-2">
                Registration
              </h3>
              <p className="text-gray-600 text-center">
                Create an account to begin the assessment process.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105">
              <div className="flex justify-center mb-4">
                <div className="bg-brand-light-blue h-16 w-16 rounded-full flex items-center justify-center">
                  <span className="text-brand-dark-blue font-bold text-xl">2</span>
                </div>
              </div>
              <h3 className="font-semibold text-brand-dark-blue text-lg text-center mb-2">
                Preliminary Assessment
              </h3>
              <p className="text-gray-600 text-center">
                Answer key questions about your AI system to determine certification needs.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105">
              <div className="flex justify-center mb-4">
                <div className="bg-brand-light-blue h-16 w-16 rounded-full flex items-center justify-center">
                  <span className="text-brand-dark-blue font-bold text-xl">3</span>
                </div>
              </div>
              <h3 className="font-semibold text-brand-dark-blue text-lg text-center mb-2">
                Results
              </h3>
              <p className="text-gray-600 text-center">
                Receive an initial determination if certification is required for your AI system.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105">
              <div className="flex justify-center mb-4">
                <div className="bg-brand-light-blue h-16 w-16 rounded-full flex items-center justify-center">
                  <span className="text-brand-dark-blue font-bold text-xl">4</span>
                </div>
              </div>
              <h3 className="font-semibold text-brand-dark-blue text-lg text-center mb-2">
                Next Steps
              </h3>
              <p className="text-gray-600 text-center">
                Receive support and guidance from White Label Consultancy on moving forward.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105">
              <div className="flex justify-center mb-4">
                <div className="bg-brand-light-blue h-16 w-16 rounded-full flex items-center justify-center">
                  <span className="text-brand-dark-blue font-bold text-xl">5</span>
                </div>
              </div>
              <h3 className="font-semibold text-brand-dark-blue text-lg text-center mb-2">
                Detailed Audit
              </h3>
              <p className="text-gray-600 text-center">
                For qualifying systems, a comprehensive audit is conducted (paid service).
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105">
              <div className="flex justify-center mb-4">
                <div className="bg-brand-light-blue h-16 w-16 rounded-full flex items-center justify-center">
                  <span className="text-brand-dark-blue font-bold text-xl">6</span>
                </div>
              </div>
              <h3 className="font-semibold text-brand-dark-blue text-lg text-center mb-2">
                DIFC Certification
              </h3>
              <p className="text-gray-600 text-center">
                Successful completion of the process leads to official DIFC certification.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
