import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  getAssessmentResults, 
  DetailedAssessmentResult, 
  determineAssessmentOutcome,
  CertificationOutcome
} from '../services/assessmentService';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';

const QualificationDecision: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<DetailedAssessmentResult | null>(null);
  const [outcome, setOutcome] = useState<CertificationOutcome | null>(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Get session ID from query params
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('sessionId') || localStorage.getItem('assessmentSessionId') || '';
        const assessmentId = searchParams.get('assessmentId') || localStorage.getItem('assessmentId') || '';
        
        if (!sessionId) {
          setError('No assessment session ID provided. Please complete the assessment first.');
          setLoading(false);
          return;
        }
        
        console.log(`Fetching results with sessionId=${sessionId}, assessmentId=${assessmentId}`);
        
        // Fetch assessment results
        const assessmentResults = await getAssessmentResults({ sessionId, assessmentId });
        
        // Make sure the assessment results are properly typed
        if (assessmentResults) {
          setResults(assessmentResults as DetailedAssessmentResult);
        }
        
        console.log('Fetched assessment results:', assessmentResults);
        
      } catch (err: any) {
        console.error('Error fetching results:', err);
        setError(err.message || 'Failed to load assessment results');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [location.search]);

  const handleContinueToCompliance = () => {
    // Navigate to compliance assessment with session ID
    const sessionId = results?.session_id || '';
    navigate(`/compliance-assessment?sessionId=${sessionId}`);
  };

  const handleContinueToFinalReport = () => {
    // Navigate to final report with session ID
    const sessionId = results?.session_id || '';
    navigate(`/final-report?sessionId=${sessionId}`);
  };

  const handleContactRequest = () => {
    // In a real implementation, this would trigger an email notification
    // For now, just show a success message and navigate to final report
    alert('Thank you! Our team will contact you shortly for assistance.');
    const sessionId = results?.session_id || '';
    navigate(`/final-report?sessionId=${sessionId}`);
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !results) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
          {error || 'Failed to load assessment results. Please try again.'}
        </div>
        <div className="flex justify-center mt-8">
          <button
            onClick={() => navigate('/preliminary-assessment')}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
          >
            Retake Assessment
          </button>
        </div>
      </div>
    );
  }

  // Use the determined outcome if available, otherwise fall back to the API results
  const requiresCertification = outcome ? outcome.requires_certification : results.requires_certification;
  const certificationReasons = outcome ? outcome.certification_reasons : results.certification_reasons;
  const summary = outcome ? outcome.summary : results.summary;

  // Determine if we're in the "Not Sure" case
  const isNotSure = summary.includes('cannot determine') || summary.includes('Undetermined');

  // Determine result classes based on certification requirement
  const resultClasses = requiresCertification
    ? 'bg-red-50 border-red-500 text-red-800'
    : isNotSure
    ? 'bg-yellow-50 border-yellow-500 text-yellow-800'
    : 'bg-green-50 border-green-500 text-green-800';

  return (
    <div className="max-w-4xl mx-auto relative">
      <div className="absolute -right-16 top-20 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-20 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Qualification Decision
        </h1>

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <div
            className={`border-l-4 p-6 mb-6 ${resultClasses}`}
          >
            <h2 className="text-2xl font-bold mb-2">
              {requiresCertification
                ? 'Certification Required'
                : isNotSure
                ? 'Additional Assessment Needed'
                : 'Certification Not Required'}
            </h2>
            <p>
              {requiresCertification
                ? 'Based on your responses, your AI system is subject to mandatory certification under DIFC Regulation 10.'
                : isNotSure
                ? 'Based on your responses, we cannot determine with certainty if certification is required. Our team will contact you for further assessment.'
                : 'Based on your responses, your AI system is not subject to mandatory certification under DIFC Regulation 10.'}
            </p>
          </div>

          {summary && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Assessment Summary</h3>
              <p className="text-gray-700">{summary}</p>
            </div>
          )}

          {certificationReasons && certificationReasons.length > 0 && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">
                {requiresCertification
                  ? 'Reasons Certification is Required'
                  : isNotSure
                  ? 'Key Considerations'
                  : 'Key Findings'}
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                {certificationReasons.map((reason: string, index: number) => (
                  <li key={index}>{reason}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="text-blue-700">
              <strong>Next Steps:</strong> {requiresCertification 
                ? 'Please proceed to the Compliance Assessment to provide detailed information about your AI system.'
                : isNotSure
                ? 'Our team will contact you to gather additional information and provide guidance.'
                : 'You can proceed to the Final Report to review your assessment results.'}
            </p>
          </div>

          <div className="flex justify-between">
            <button
              onClick={() => navigate('/preliminary-assessment')}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-3 px-8 rounded-lg transition duration-300"
            >
              Back to Assessment
            </button>
            
            {requiresCertification ? (
              <button
                onClick={handleContinueToCompliance}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
              >
                Continue to Compliance Assessment
              </button>
            ) : isNotSure ? (
              <button
                onClick={handleContactRequest}
                className="bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
              >
                Request Assistance
              </button>
            ) : (
              <button
                onClick={handleContinueToFinalReport}
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
              >
                Continue to Final Report
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualificationDecision;
