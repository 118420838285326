import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  getAssessmentResults, 
  DetailedAssessmentResult
} from '../services/assessmentService';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';
import ReportGenerator from '../components/ReportGenerator';

const FinalReport: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<DetailedAssessmentResult | null>(null);
  const [sessionId, setSessionId] = useState<string>('');
  const [registrationData, setRegistrationData] = useState<any>(null);
  const [auditData, setAuditData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Get session ID from query params
        const searchParams = new URLSearchParams(location.search);
        const sid = searchParams.get('sessionId') || localStorage.getItem('assessmentSessionId') || '';
        
        if (!sid) {
          setError('No assessment session ID provided. Please complete the assessment first.');
          setLoading(false);
          return;
        }
        
        setSessionId(sid);
        
        // Fetch assessment results
        const assessmentResults = await getAssessmentResults({ sessionId: sid });
        
        // Make sure the assessment results are properly typed
        if (assessmentResults) {
          setResults(assessmentResults as DetailedAssessmentResult);
        }
        
        // Get registration data from localStorage
        const savedRegistrationData = localStorage.getItem('registration_data');
        if (savedRegistrationData) {
          try {
            const parsedData = JSON.parse(savedRegistrationData);
            setRegistrationData(parsedData);
          } catch (err) {
            console.error('Error parsing saved registration data:', err);
          }
        }
        
        // Get audit data from localStorage
        const savedAuditData = localStorage.getItem(`audit_data_${sid}`) || localStorage.getItem('audit_data_final');
        if (savedAuditData) {
          try {
            const parsedData = JSON.parse(savedAuditData);
            setAuditData(parsedData);
          } catch (err) {
            console.error('Error parsing saved audit data:', err);
          }
        }
        
      } catch (err: any) {
        console.error('Error fetching results:', err);
        setError(err.message || 'Failed to load assessment results');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.search]);

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !results) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
          {error || 'Failed to load assessment results. Please try again.'}
        </div>
        <div className="flex justify-center mt-8">
          <button
            onClick={() => navigate('/preliminary-assessment')}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
          >
            Retake Assessment
          </button>
        </div>
      </div>
    );
  }

  const requiresCertification = results.requires_certification;
  const certificationReasons = results.certification_reasons;
  const summary = results.summary;

  // Determine if we're in the "Not Sure" case
  const isNotSure = summary.includes('cannot determine') || summary.includes('Undetermined');

  // Determine result classes based on certification requirement
  const resultClasses = requiresCertification
    ? 'bg-red-50 border-red-500 text-red-800'
    : isNotSure
    ? 'bg-yellow-50 border-yellow-500 text-yellow-800'
    : 'bg-green-50 border-green-500 text-green-800';

  return (
    <div className="max-w-5xl mx-auto relative">
      <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Final Assessment Report
        </h1>
        
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <div
            className={`border-l-4 p-6 mb-6 ${resultClasses}`}
          >
            <h2 className="text-2xl font-bold mb-2">
              {requiresCertification
                ? 'Certification Required'
                : isNotSure
                ? 'Additional Assessment Needed'
                : 'Certification Not Required'}
            </h2>
            <p>
              {requiresCertification
                ? 'Based on your responses, your AI system is subject to mandatory certification under DIFC Regulation 10.'
                : isNotSure
                ? 'Based on your responses, we cannot determine with certainty if certification is required. Our team will contact you for further assessment.'
                : 'Based on your responses, your AI system is not subject to mandatory certification under DIFC Regulation 10.'}
            </p>
          </div>

          {summary && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Assessment Summary</h3>
              <p className="text-gray-700">{summary}</p>
            </div>
          )}

          {certificationReasons && certificationReasons.length > 0 && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">
                {requiresCertification
                  ? 'Reasons Certification is Required'
                  : isNotSure
                  ? 'Key Considerations'
                  : 'Key Findings'}
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                {certificationReasons.map((reason: string, index: number) => (
                  <li key={index}>{reason}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">Your Responses</h3>
            <div className="space-y-6">
              {results.responses && results.responses.length > 0 ? results.responses.map((response: any) => (
                <div key={response.question_id} className="border-b pb-4">
                  <p className="font-medium text-gray-800 mb-2">
                    <span className="text-blue-700 mr-2">{response.question_id}:</span> 
                    {response.question_text}
                  </p>
                  
                  <p className="text-gray-600">
                    <span className="font-medium">Your answer: </span>
                    {response.value !== undefined && response.value !== null ? 
                      (Array.isArray(response.value) 
                        ? response.value.join(', ') 
                        : response.value)
                      : <span className="italic text-gray-400">No response provided</span>
                    }
                  </p>
                </div>
              )) : (
                <p className="text-red-600">No response data available.</p>
              )}
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">Next Steps</h3>
            <div className="bg-blue-50 p-4 rounded-lg">
              <ul className="list-decimal pl-6 space-y-2 text-gray-700">
                {requiresCertification ? (
                  <>
                    <li>Contact an Accredited Certification Body</li>
                    <li>Complete the full assessment process</li>
                    <li>Submit required documentation for review</li>
                    <li>Implement any required changes to your AI system</li>
                    <li>Obtain certification upon approval</li>
                  </>
                ) : (
                  <>
                    <li>Continue to monitor your AI system for changes that might affect certification requirements</li>
                    <li>Implement best practices for responsible AI development and deployment</li>
                    <li>Stay informed about updates to DIFC Regulation 10</li>
                    <li>Consider voluntary certification if your AI system's risk profile changes</li>
                  </>
                )}
              </ul>
            </div>
          </div>

          {/* Report Generator Component */}
          <ReportGenerator 
            registrationData={registrationData}
            assessmentResults={results}
            auditData={auditData}
            sessionId={sessionId}
          />
        </div>
        
        <div className="flex justify-between">
          <button
            onClick={() => navigate('/preliminary-assessment')}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-3 px-8 rounded-lg transition duration-300"
          >
            Start New Assessment
          </button>
          
          {requiresCertification && !auditData && (
            <button
              onClick={() => navigate(`/audit-questions?sessionId=${sessionId}`)}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
            >
              Complete Audit Questions
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinalReport;
