import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../context/AuthContext';
// Import the CSS for decorative elements and the logo
import '../styles/decorations.css';
import logo from '../assets/images/WL-logo-dark-blue.png';

// Validation schema
const RegisterSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  privacy_terms_accepted: Yup.boolean()
    .oneOf([true], 'You must accept the privacy terms')
    .required('You must accept the privacy terms')
});

const Register: React.FC = () => {
  const { register, user, error, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formError, setFormError] = useState<string | null>(null);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  // Handle registration success and navigate to applicant form
  useEffect(() => {
    if (user && registrationSuccess) {
      // Set a flag in localStorage to indicate this is a new registration
      localStorage.setItem('recent_registration', 'true');
      
      // Navigate to the applicant form with a query parameter
      navigate('/applicant-form?new=true');
    } else if (user && !registrationSuccess) {
      // If the user is already logged in but not from a new registration
      navigate('/applicant-form');
    }
  }, [user, navigate, registrationSuccess]);

  // Update form error when context error changes
  useEffect(() => {
    setFormError(error);
  }, [error]);

  return (
    <div className="max-w-md mx-auto relative">
      {/* Decorative elements */}
      <div className="auth-decoration"></div>
      
      <div className="relative z-10">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="White Label Consultancy" className="h-16" />
        </div>
        
        <h1 className="text-3xl font-headlines font-bold text-center text-brand-dark-blue mb-8">
          Create an Account
        </h1>

        <div className="bg-white rounded-lg shadow-lg p-8">
          <Formik
            initialValues={{
              name: '',
              email: '',
              password: '',
              confirmPassword: '',
              privacy_terms_accepted: false
            }}
            validationSchema={RegisterSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setFormError(null);
              try {
                await register(values.name, values.email, values.password, values.privacy_terms_accepted);
                // Set registration success state to true
                setRegistrationSuccess(true);
              } catch (error: any) {
                console.error('Registration error:', error);
                // Extract error message from the error object
                const errorMessage = 
                  error.response?.data?.error || 
                  error.response?.data?.message || 
                  error.message || 
                  'Registration failed. Please try again.';
                  
                setFormError(errorMessage);
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-6">
                {formError && (
                  <div className="bg-red-100 border border-brand-red text-brand-red px-4 py-3 rounded">
                    {formError}
                  </div>
                )}

                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Full Name
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-brand-light-blue focus:border-brand-light-blue"
                    placeholder="Enter your full name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-brand-red text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email Address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-brand-light-blue focus:border-brand-light-blue"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-brand-red text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Password
                  </label>
                  <Field
                    type="password"
                    name="password"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-brand-light-blue focus:border-brand-light-blue"
                    placeholder="Create a password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-brand-red text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Confirm Password
                  </label>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-brand-light-blue focus:border-brand-light-blue"
                    placeholder="Confirm your password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-brand-red text-sm mt-1"
                  />
                </div>

                {/* Privacy Terms Checkbox */}
                <div className="mt-4">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <Field
                        type="checkbox"
                        name="privacy_terms_accepted"
                        className="h-4 w-4 text-brand-light-blue border-gray-300 rounded focus:ring-brand-light-blue"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="privacy_terms_accepted" className="font-medium text-gray-700">
                        I have read and accept the Privacy Policy and Terms of Service
                      </label>
                      <ErrorMessage
                        name="privacy_terms_accepted"
                        component="div"
                        className="text-brand-red text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting || loading}
                  className="w-full bg-brand-light-blue hover:bg-brand-very-light-blue text-brand-dark-blue font-bold py-2 px-4 rounded-md transition-colors duration-300 disabled:opacity-50"
                >
                  {loading ? 'Creating Account...' : 'Register'}
                </button>
              </Form>
            )}
          </Formik>

          <div className="mt-6 text-center">
            <p className="text-gray-600">
              Already have an account?{' '}
              <Link to="/login" className="text-brand-light-blue hover:text-brand-medium-blue">
                Login here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register; 