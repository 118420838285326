import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  getAssessmentResults, 
  DetailedAssessmentResult, 
  ResponseData 
} from '../services/assessmentService';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';

interface AuditFormData {
  transparency_statement: string;
  ai_register: string;
  risk_assessment: string;
  human_oversight: string;
  data_governance: string;
  additional_documentation: string;
}

interface RegistrationFormData {
  organisation_name: string;
  organisation_address: string;
  industry_sector: string;
  user_name: string;
  user_email: string;
  terms_accepted: boolean;
}

const AssessmentSummary: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string>('');
  const [assessmentResults, setAssessmentResults] = useState<DetailedAssessmentResult | null>(null);
  const [auditData, setAuditData] = useState<AuditFormData | null>(null);
  const [registrationData, setRegistrationData] = useState<RegistrationFormData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Get session ID from query params or localStorage
        const searchParams = new URLSearchParams(location.search);
        const sid = searchParams.get('sessionId') || localStorage.getItem('assessmentSessionId') || '';
        
        if (!sid) {
          setError('No assessment session ID found. Please complete the previous steps first.');
          setLoading(false);
          return;
        }
        
        setSessionId(sid);
        
        // Fetch assessment results
        try {
          const results = await getAssessmentResults({ sessionId: sid });
          setAssessmentResults(results as DetailedAssessmentResult);
        } catch (err) {
          console.error('Error fetching assessment results:', err);
        }
        
        // Get audit data from localStorage
        const savedAuditData = localStorage.getItem(`audit_data_${sid}`) || localStorage.getItem('audit_data_final');
        if (savedAuditData) {
          try {
            const parsedData = JSON.parse(savedAuditData);
            setAuditData(parsedData);
          } catch (err) {
            console.error('Error parsing saved audit data:', err);
          }
        }
        
        // Get registration data from localStorage
        const savedRegistrationData = localStorage.getItem('registration_data');
        if (savedRegistrationData) {
          try {
            const parsedData = JSON.parse(savedRegistrationData);
            setRegistrationData(parsedData);
          } catch (err) {
            console.error('Error parsing saved registration data:', err);
          }
        }
      } catch (err: any) {
        setError(err.message || 'An error occurred while loading the summary');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [location.search]);

  const handleSubmit = () => {
    // Navigate to the final report
    navigate(`/final-report?sessionId=${sessionId}`);
  };

  const handleEdit = (section: string) => {
    switch (section) {
      case 'registration':
        navigate('/registration');
        break;
      case 'qualification':
        navigate('/preliminary-assessment');
        break;
      case 'audit':
        navigate(`/audit-questions?sessionId=${sessionId}`);
        break;
      default:
        break;
    }
  };

  if (loading) {
    return (
      <div className="max-w-5xl mx-auto flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto relative">
      {/* Decorative elements */}
      <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Assessment Summary
        </h1>

        {/* Progress indicator */}
        <div className="mb-8">
          <div className="flex justify-between mb-2">
            <div>Registration</div>
            <div>Qualification</div>
            <div>Compliance</div>
            <div>Audit</div>
            <div className="font-bold">Summary</div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '100%' }}></div>
          </div>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
            {error}
          </div>
        )}

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="text-blue-700">
              <strong>Review your assessment:</strong> Please review the information below before final submission. 
              You can edit any section by clicking the "Edit" button.
            </p>
          </div>

          {/* Registration Information */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">Registration Information</h2>
              <button
                onClick={() => handleEdit('registration')}
                className="text-blue-600 hover:text-blue-800"
              >
                Edit
              </button>
            </div>
            
            {registrationData ? (
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="font-medium text-gray-700">Organization Name:</p>
                    <p>{registrationData.organisation_name}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-700">Industry Sector:</p>
                    <p>{registrationData.industry_sector}</p>
                  </div>
                  <div className="md:col-span-2">
                    <p className="font-medium text-gray-700">Organization Address:</p>
                    <p>{registrationData.organisation_address}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-700">User Name:</p>
                    <p>{registrationData.user_name}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-700">User Email:</p>
                    <p>{registrationData.user_email}</p>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-gray-500 italic">No registration information available.</p>
            )}
          </div>

          {/* Qualification Assessment */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">Qualification Assessment</h2>
              <button
                onClick={() => handleEdit('qualification')}
                className="text-blue-600 hover:text-blue-800"
              >
                Edit
              </button>
            </div>
            
            {assessmentResults ? (
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="mb-4">
                  <p className="font-medium text-gray-700">Assessment Result:</p>
                  <p className={`font-bold ${assessmentResults.requires_certification ? 'text-red-600' : 'text-green-600'}`}>
                    {assessmentResults.requires_certification ? 'Certification Required' : 'Certification Not Required'}
                  </p>
                </div>
                
                <div className="mb-4">
                  <p className="font-medium text-gray-700">Summary:</p>
                  <p>{assessmentResults.summary}</p>
                </div>
                
                {assessmentResults.certification_reasons && assessmentResults.certification_reasons.length > 0 && (
                  <div className="mb-4">
                    <p className="font-medium text-gray-700">Key Findings:</p>
                    <ul className="list-disc pl-5">
                      {assessmentResults.certification_reasons.map((reason, index) => (
                        <li key={index}>{reason}</li>
                      ))}
                    </ul>
                  </div>
                )}
                
                <div>
                  <p className="font-medium text-gray-700">Your Responses:</p>
                  <div className="mt-2 space-y-2">
                    {assessmentResults.responses && assessmentResults.responses.map((response: ResponseData) => (
                      <div key={response.question_id} className="border-b border-gray-200 pb-2">
                        <p className="text-sm text-gray-600">{response.question_text}</p>
                        <p className="font-medium">
                          {Array.isArray(response.value) 
                            ? response.value.join(', ') 
                            : response.value}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-gray-500 italic">No qualification assessment results available.</p>
            )}
          </div>

          {/* Audit Information */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">Audit Information</h2>
              <button
                onClick={() => handleEdit('audit')}
                className="text-blue-600 hover:text-blue-800"
              >
                Edit
              </button>
            </div>
            
            {auditData ? (
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="space-y-4">
                  <div>
                    <p className="font-medium text-gray-700">Do you provide clear and easily accessible statements explaining the use and implications of your AI system?</p>
                    <p>{auditData.transparency_statement}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-700">Do you maintain a register of AI Systems that operate autonomously?</p>
                    <p>{auditData.ai_register}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-700">Have you conducted a risk assessment for your AI system?</p>
                    <p>{auditData.risk_assessment}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-700">Do you have human oversight measures in place for your AI system?</p>
                    <p>{auditData.human_oversight}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-700">Do you have data governance policies for your AI system?</p>
                    <p>{auditData.data_governance}</p>
                  </div>
                  {auditData.additional_documentation && (
                    <div>
                      <p className="font-medium text-gray-700">Additional Documentation or Comments:</p>
                      <p>{auditData.additional_documentation}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p className="text-gray-500 italic">No audit information available.</p>
            )}
          </div>

          <div className="flex justify-between">
            <button
              onClick={() => navigate(`/audit-questions?sessionId=${sessionId}`)}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded-lg transition duration-300"
            >
              Back
            </button>
            
            <button
              onClick={handleSubmit}
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
            >
              Submit and View Final Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentSummary;
