import React from 'react';
import { Link } from 'react-router-dom';
// Import decorative elements
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg'; 
import asset10 from '../assets/images/Asset 10 copy.svg';

const CertificationDetails: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto relative">
      {/* Decorative elements */}
      <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      <div className="absolute right-20 top-80 opacity-3 w-96 h-96 hidden md:block">
        <img src={asset10} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-4xl md:text-5xl font-headlines font-bold text-center text-brand-dark-blue mb-8">
          AI Certification Process
        </h1>

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <h2 className="text-2xl font-headlines font-semibold text-brand-dark-blue mb-4">
            How White Label Consultancy Can Help
          </h2>
          
          <p className="text-gray-600 mb-6">
            At White Label Consultancy, we specialize in guiding organizations through the DIFC AI certification process. Our team of experts will work closely with you to ensure compliance with DIFC Regulation 10 and help you achieve certification efficiently.
          </p>

          <div className="border-l-4 border-brand-light-blue pl-4 py-2 mb-6 bg-brand-very-light-blue">
            <p className="text-brand-medium-blue">
              <strong>Why choose us:</strong> With our deep understanding of DIFC regulations and AI systems, we provide tailored support that minimizes disruption to your business while ensuring regulatory compliance.
            </p>
          </div>

          <h3 className="text-xl font-headlines font-semibold text-brand-dark-blue mb-3">Our Certification Support Services</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div className="border border-gray-200 rounded-lg p-5 hover:shadow-md transition-shadow">
              <h4 className="text-lg font-semibold text-brand-medium-blue mb-2">Assessment & Gap Analysis</h4>
              <p className="text-gray-600">Comprehensive evaluation of your AI system against DIFC requirements, identifying compliance gaps.</p>
            </div>
            
            <div className="border border-gray-200 rounded-lg p-5 hover:shadow-md transition-shadow">
              <h4 className="text-lg font-semibold text-brand-medium-blue mb-2">Documentation Preparation</h4>
              <p className="text-gray-600">Expert assistance with preparing all required technical and compliance documentation.</p>
            </div>
            
            <div className="border border-gray-200 rounded-lg p-5 hover:shadow-md transition-shadow">
              <h4 className="text-lg font-semibold text-brand-medium-blue mb-2">System Enhancement</h4>
              <p className="text-gray-600">Guidance on implementing necessary changes to meet certification requirements.</p>
            </div>
            
            <div className="border border-gray-200 rounded-lg p-5 hover:shadow-md transition-shadow">
              <h4 className="text-lg font-semibold text-brand-medium-blue mb-2">Certification Management</h4>
              <p className="text-gray-600">End-to-end management of the certification process, including liaison with accredited certification bodies.</p>
            </div>
          </div>
          
          <h3 className="text-xl font-headlines font-semibold text-brand-dark-blue mb-3">Certification Timeline</h3>
          <p className="text-gray-600 mb-4">
            The certification process typically takes 8-12 weeks, depending on the complexity of your AI system and its current state of compliance.
          </p>
          
          <div className="bg-brand-very-light-blue p-5 rounded-lg mb-8">
            <h4 className="text-lg font-semibold text-brand-dark-blue mb-3">Process Steps:</h4>
            <ol className="list-decimal pl-6 space-y-2 text-gray-600">
              <li>Initial consultation and scope definition (1 week)</li>
              <li>Compliance gap analysis and assessment (2-3 weeks)</li>
              <li>Documentation preparation and system adjustments (3-4 weeks)</li>
              <li>Pre-certification review and validation (1 week)</li>
              <li>Formal certification audit and process (1-3 weeks)</li>
            </ol>
          </div>
        </div>

        <div className="bg-brand-very-light-blue rounded-lg p-8 border border-brand-muted-blue mb-8">
          <h3 className="text-xl font-headlines font-semibold text-brand-dark-blue mb-4">Pricing Structure</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <div className="bg-white rounded-lg shadow-md p-6 border-t-4 border-brand-medium-blue flex flex-col h-full">
              <h4 className="text-xl font-bold text-center text-brand-dark-blue mb-2">Basic Package</h4>
              <p className="text-3xl text-center font-bold text-brand-dark-blue mb-4">15,000 AED</p>
              <ul className="list-disc pl-6 text-gray-600 mb-6 flex-grow">
                <li>Initial assessment and gap analysis</li>
                <li>Basic documentation support</li>
                <li>Certification application assistance</li>
                <li>Up to 15 hours of consultation</li>
              </ul>
              <p className="text-sm text-gray-600 text-center">Ideal for smaller AI systems with limited scope</p>
            </div>
            
            <div className="bg-white rounded-lg shadow-md p-6 border-t-4 border-brand-light-blue flex flex-col h-full transform scale-105">
              <div className="bg-brand-light-blue text-brand-dark-blue text-xs font-bold uppercase py-1 px-2 rounded-full text-center w-fit mx-auto -mt-9 mb-3">Most Popular</div>
              <h4 className="text-xl font-bold text-center text-brand-dark-blue mb-2">Professional Package</h4>
              <p className="text-3xl text-center font-bold text-brand-dark-blue mb-4">25,000 AED</p>
              <ul className="list-disc pl-6 text-gray-600 mb-6 flex-grow">
                <li>Comprehensive assessment and gap analysis</li>
                <li>Complete documentation preparation</li>
                <li>Implementation guidance for required changes</li>
                <li>Up to 30 hours of consultation</li>
                <li>Certification body liaison and management</li>
              </ul>
              <p className="text-sm text-gray-600 text-center">Suitable for most AI systems requiring certification</p>
            </div>
            
            <div className="bg-white rounded-lg shadow-md p-6 border-t-4 border-brand-medium-blue flex flex-col h-full">
              <h4 className="text-xl font-bold text-center text-brand-dark-blue mb-2">Enterprise Package</h4>
              <p className="text-3xl text-center font-bold text-brand-dark-blue mb-4">50,000 AED</p>
              <ul className="list-disc pl-6 text-gray-600 mb-6 flex-grow">
                <li>Everything in Professional package</li>
                <li>Custom implementation support</li>
                <li>Advanced compliance framework development</li>
                <li>Post-certification monitoring and support</li>
                <li>Up to 60 hours of consultation</li>
              </ul>
              <p className="text-sm text-gray-600 text-center">For complex or high-risk AI systems</p>
            </div>
          </div>
          
          <p className="text-sm text-brand-dark-blue text-center mb-8">
            All packages can be customized to meet your specific needs. Contact us for a detailed quote.
          </p>
          
          <div className="text-center">
            <Link to="/results" className="bg-brand-dark-blue hover:bg-brand-medium-blue text-white font-bold py-2 px-6 rounded-lg transition-colors duration-300 mr-4">
              Back to Results
            </Link>
            <a href="mailto:contact@whitelabelconsultancy.com" className="bg-brand-light-blue hover:bg-brand-very-light-blue text-brand-dark-blue font-bold py-2 px-6 rounded-lg border border-brand-light-blue transition-colors duration-300">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationDetails; 