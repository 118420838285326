import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getExtendedAssessmentQuestions,
  saveExtendedAssessmentResponses,
  uploadFile,
  ExtendedAssessmentQuestion,
  ExtendedAssessmentSectionMap,
  ExtendedAssessmentResponse
} from '../../services/extendedAssessmentService';

const ExtendedAssessmentForm: React.FC = () => {
  const [questions, setQuestions] = useState<ExtendedAssessmentQuestion[]>([]);
  const [responses, setResponses] = useState<Record<string, string | string[]>>({});
  const [filePathMap, setFilePathMap] = useState<Record<string, string>>({});
  const [sections, setSections] = useState<ExtendedAssessmentSectionMap>({});
  const [currentSection, setCurrentSection] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadingFile, setUploadingFile] = useState<Record<string, boolean>>({});
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const navigate = useNavigate();

  // Load questions and user's existing responses
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const data = await getExtendedAssessmentQuestions();
        setQuestions(data.questions);
        setSections(data.sections);
        
        // Set default section
        if (data.questions.length > 0 && data.questions[0].section) {
          setCurrentSection(data.questions[0].section);
        }
        
        // Load existing responses if any
        const existingResponses: Record<string, string | string[]> = {};
        const existingFilePaths: Record<string, string> = {};
        
        Object.entries(data.responses).forEach(([questionId, response]) => {
          existingResponses[questionId] = response.value;
          if (response.file_path) {
            existingFilePaths[questionId] = response.file_path;
          }
        });
        
        setResponses(existingResponses);
        setFilePathMap(existingFilePaths);
      } catch (err: any) {
        setError(err.message || 'Failed to load extended assessment questions');
      } finally {
        setLoading(false);
      }
    };
    
    fetchQuestions();
  }, []);

  // Handle response changes
  const handleResponseChange = (questionId: string, value: string | string[]) => {
    setResponses(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  // Handle file uploads
  const handleFileUpload = async (questionId: string, file: File) => {
    try {
      setUploadingFile(prev => ({ ...prev, [questionId]: true }));
      setUploadProgress(prev => ({ ...prev, [questionId]: 0 }));
      
      // Simulate progress
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => {
          const currentProgress = prev[questionId] || 0;
          if (currentProgress < 90) {
            return { ...prev, [questionId]: currentProgress + 10 };
          }
          return prev;
        });
      }, 300);
      
      const result = await uploadFile(file, questionId);
      
      clearInterval(progressInterval);
      setUploadProgress(prev => ({ ...prev, [questionId]: 100 }));
      
      if (result.success && result.data?.filePath) {
        setFilePathMap(prev => ({
          ...prev,
          [questionId]: result.data!.filePath
        }));
        
        // Update response to indicate file was uploaded
        handleResponseChange(questionId, 'File uploaded');
      }
    } catch (err: any) {
      setError(`Failed to upload file: ${err.message}`);
    } finally {
      setUploadingFile(prev => ({ ...prev, [questionId]: false }));
    }
  };

  // Save all responses
  const handleSave = async (isFinal: boolean = false) => {
    try {
      setSaving(true);
      setError(null);
      
      const responsesToSave: ExtendedAssessmentResponse[] = Object.entries(responses).map(([questionId, value]) => ({
        questionId,
        value,
        filePath: filePathMap[questionId]
      }));
      
      await saveExtendedAssessmentResponses(responsesToSave);
      
      if (isFinal) {
        // Navigate to results page
        navigate('/extended-assessment-results');
      }
    } catch (err: any) {
      setError(err.message || 'Failed to save responses');
    } finally {
      setSaving(false);
    }
  };

  // Get questions for the current section
  const getCurrentSectionQuestions = () => {
    return questions.filter(q => q.section === currentSection);
  };

  // Get all section names
  const sectionNames = Object.keys(sections);

  // Check if a question should be displayed based on parent-child relationships
  const shouldShowQuestion = (question: ExtendedAssessmentQuestion): boolean => {
    if (!question.parent_question_id) {
      return true;
    }
    
    // Check if the parent question has the expected answer
    const parentResponse = responses[question.parent_question_id];
    return parentResponse === question.parent_answer;
  };

  if (loading) {
    return (
      <div className="container mt-5">
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3">Loading assessment questions...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
        <button
          onClick={() => window.location.reload()}
          className="btn btn-primary"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Extended Assessment</h1>
      
      {/* Section navigation */}
      <div className="mb-4">
        <h2 className="h5 mb-3">Assessment Sections</h2>
        <div className="d-flex flex-wrap gap-2">
          {sectionNames.map((section) => (
            <button
              key={section}
              onClick={() => setCurrentSection(section)}
              className={`btn ${
                currentSection === section
                  ? 'btn-primary'
                  : 'btn-outline-secondary'
              }`}
            >
              {sections[section]}
            </button>
          ))}
        </div>
      </div>
      
      {/* Current section and questions */}
      <div className="card shadow-sm mb-4">
        <div className="card-body">
          <h2 className="card-title">
            {sections[currentSection] || 'Assessment Questions'}
          </h2>
          
          {getCurrentSectionQuestions().length === 0 ? (
            <p className="text-muted">No questions available for this section.</p>
          ) : (
            <div className="mt-4">
              {getCurrentSectionQuestions().map((question) => (
                shouldShowQuestion(question) && (
                  <div key={question.question_id} className="mb-4 pb-4 border-bottom">
                    <div className="mb-3">
                      <h3 className="h5">
                        {question.text}
                        {question.is_required && <span className="text-danger ms-1">*</span>}
                      </h3>
                      
                      {question.guidance_text && (
                        <p className="text-muted small">{question.guidance_text}</p>
                      )}
                    </div>
                    
                    {/* Question response inputs based on type */}
                    <div className="mt-3">
                      {/* Yes/No Question */}
                      {question.type === 'yes_no' && (
                        <div className="d-flex space-x-4">
                          <label className="d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input"
                              checked={responses[question.question_id] === 'Yes'}
                              onChange={() => handleResponseChange(question.question_id, 'Yes')}
                            />
                            <span className="ms-2">Yes</span>
                          </label>
                          
                          <label className="d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input"
                              checked={responses[question.question_id] === 'No'}
                              onChange={() => handleResponseChange(question.question_id, 'No')}
                            />
                            <span className="ms-2">No</span>
                          </label>
                        </div>
                      )}
                      
                      {/* Text Question */}
                      {question.type === 'text' && (
                        <textarea
                          className="form-control"
                          value={responses[question.question_id] as string || ''}
                          onChange={(e) => handleResponseChange(question.question_id, e.target.value)}
                          rows={4}
                          placeholder="Enter your answer here..."
                        />
                      )}
                      
                      {/* File Upload Question */}
                      {question.type === 'file_upload' && (
                        <div>
                          {filePathMap[question.question_id] ? (
                            <div className="d-flex align-items-center bg-success text-white p-3 rounded mb-3">
                              <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill"/></svg>
                              <span>{filePathMap[question.question_id].split('_').pop()}</span>
                              <button
                                className="btn-close btn-close-white ms-auto"
                                type="button"
                                onClick={() => {
                                  setFilePathMap(prev => {
                                    const newMap = { ...prev };
                                    delete newMap[question.question_id];
                                    return newMap;
                                  });
                                  handleResponseChange(question.question_id, '');
                                }}
                              ></button>
                            </div>
                          ) : (
                            <div>
                              <input
                                type="file"
                                className="form-control"
                                id={`file-upload-${question.question_id}`}
                                onChange={(e) => {
                                  if (e.target.files && e.target.files[0]) {
                                    handleFileUpload(question.question_id, e.target.files[0]);
                                  }
                                }}
                              />
                              <label
                                htmlFor={`file-upload-${question.question_id}`}
                                className="form-label"
                              >
                                {uploadingFile[question.question_id]
                                  ? 'Uploading...'
                                  : 'Select File to Upload'}
                              </label>
                            </div>
                          )}
                          
                          {uploadingFile[question.question_id] && (
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                style={{ width: `${uploadProgress[question.question_id] || 0}%` }}
                              ></div>
                            </div>
                          )}
                        </div>
                      )}
                      
                      {/* Checkbox Question */}
                      {question.type === 'checkbox' && (
                        <div className="mt-3">
                          <div>
                            {/* Hardcoded for Q9 specifically */}
                            {question.question_id === 'EXT_Q9' && (
                              <>
                                <label className="d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      Array.isArray(responses[question.question_id]) &&
                                      (responses[question.question_id] as string[]).includes('internal_guidelines')
                                    }
                                    onChange={(e) => {
                                      const currentValues = Array.isArray(responses[question.question_id])
                                        ? [...responses[question.question_id] as string[]]
                                        : [];
                                      let newValues;
                                      if (e.target.checked) {
                                        newValues = [...currentValues, 'internal_guidelines'];
                                      } else {
                                        newValues = currentValues.filter(v => v !== 'internal_guidelines');
                                      }
                                      handleResponseChange(question.question_id, newValues);
                                    }}
                                  />
                                  <span className="ms-2">Internal Guidelines or Policies</span>
                                </label>
                                
                                <label className="d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      Array.isArray(responses[question.question_id]) &&
                                      (responses[question.question_id] as string[]).includes('contracts')
                                    }
                                    onChange={(e) => {
                                      const currentValues = Array.isArray(responses[question.question_id])
                                        ? [...responses[question.question_id] as string[]]
                                        : [];
                                      let newValues;
                                      if (e.target.checked) {
                                        newValues = [...currentValues, 'contracts'];
                                      } else {
                                        newValues = currentValues.filter(v => v !== 'contracts');
                                      }
                                      handleResponseChange(question.question_id, newValues);
                                    }}
                                  />
                                  <span className="ms-2">Contracts</span>
                                </label>
                                
                                <label className="d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      Array.isArray(responses[question.question_id]) &&
                                      (responses[question.question_id] as string[]).includes('industry_compliance')
                                    }
                                    onChange={(e) => {
                                      const currentValues = Array.isArray(responses[question.question_id])
                                        ? [...responses[question.question_id] as string[]]
                                        : [];
                                      let newValues;
                                      if (e.target.checked) {
                                        newValues = [...currentValues, 'industry_compliance'];
                                      } else {
                                        newValues = currentValues.filter(v => v !== 'industry_compliance');
                                      }
                                      handleResponseChange(question.question_id, newValues);
                                    }}
                                  />
                                  <span className="ms-2">Compliance with industry or sector laws</span>
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      
                      {/* Child questions that depend on this question's response */}
                      {question.children && question.children.length > 0 && responses[question.question_id] && (
                        <div className="mt-4 pl-4 border-start">
                          {question.children
                            .filter(child => shouldShowQuestion(child))
                            .map(child => (
                              <div key={child.question_id} className="mt-4">
                                <h4 className="h6">
                                  {child.text}
                                  {child.is_required && <span className="text-danger ms-1">*</span>}
                                </h4>
                                
                                {child.guidance_text && (
                                  <p className="text-muted small">{child.guidance_text}</p>
                                )}
                                
                                <div className="mt-2">
                                  {child.type === 'text' && (
                                    <textarea
                                      className="form-control"
                                      value={responses[child.question_id] as string || ''}
                                      onChange={(e) => handleResponseChange(child.question_id, e.target.value)}
                                      rows={3}
                                      placeholder="Enter your answer here..."
                                    />
                                  )}
                                  
                                  {child.type === 'file_upload' && (
                                    <div>
                                      {filePathMap[child.question_id] ? (
                                        <div className="d-flex align-items-center bg-success text-white p-3 rounded mb-3">
                                          <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill"/></svg>
                                          <span>{filePathMap[child.question_id].split('_').pop()}</span>
                                          <button
                                            className="btn-close btn-close-white ms-auto"
                                            type="button"
                                            onClick={() => {
                                              setFilePathMap(prev => {
                                                const newMap = { ...prev };
                                                delete newMap[child.question_id];
                                                return newMap;
                                              });
                                              handleResponseChange(child.question_id, '');
                                            }}
                                          ></button>
                                        </div>
                                      ) : (
                                        <div>
                                          <input
                                            type="file"
                                            className="form-control"
                                            id={`file-upload-${child.question_id}`}
                                            onChange={(e) => {
                                              if (e.target.files && e.target.files[0]) {
                                                handleFileUpload(child.question_id, e.target.files[0]);
                                              }
                                            }}
                                          />
                                          <label
                                            htmlFor={`file-upload-${child.question_id}`}
                                            className="form-label"
                                          >
                                            Select File to Upload
                                          </label>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                )
              ))}
            </div>
          )}
        </div>
      </div>
      
      {/* Navigation buttons */}
      <div className="d-flex justify-between mt-4">
        <div>
          {sectionNames.indexOf(currentSection) > 0 && (
            <button
              onClick={() => {
                const currentIndex = sectionNames.indexOf(currentSection);
                setCurrentSection(sectionNames[currentIndex - 1]);
              }}
              className="btn btn-outline-secondary"
            >
              Previous Section
            </button>
          )}
        </div>
        
        <div className="d-flex gap-3">
          <button
            onClick={() => handleSave(false)}
            disabled={saving}
            className="btn btn-outline-primary"
          >
            {saving ? 'Saving...' : 'Save Progress'}
          </button>
          
          {sectionNames.indexOf(currentSection) < sectionNames.length - 1 ? (
            <button
              onClick={() => {
                handleSave(false);
                const currentIndex = sectionNames.indexOf(currentSection);
                setCurrentSection(sectionNames[currentIndex + 1]);
              }}
              disabled={saving}
              className="btn btn-primary"
            >
              Next Section
            </button>
          ) : (
            <button
              onClick={() => handleSave(true)}
              disabled={saving}
              className="btn btn-success"
            >
              {saving ? 'Submitting...' : 'Submit Assessment'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExtendedAssessmentForm;
