import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { AuthContext } from '../../context/AuthContext';
import { getExtendedAssessmentQuestions, ExtendedAssessmentQuestion } from '../../services/extendedAssessmentService';

// User interface definition
interface User {
  _id: string;
  name: string;
  email: string;
  token: string;
  privacy_terms_accepted?: boolean;
}

const ExtendedAssessmentResults: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [generating, setGenerating] = useState<boolean>(false);
  const [questions, setQuestions] = useState<ExtendedAssessmentQuestion[]>([]);
  const [responses, setResponses] = useState<Record<string, {value: string, file_path?: string}>>({}); 
  const [sections, setSections] = useState<Record<string, string>>({});
  const { user } = useContext(AuthContext) as { user: User | null };
  
  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getExtendedAssessmentQuestions();
        setQuestions(data.questions);
        setResponses(data.responses);
        setSections(data.sections);
      } catch (err: any) {
        setError(err.message || 'Failed to load assessment results');
      } finally {
        setLoading(false);
      }
    };
    
    fetchResults();
  }, []);

  const generatePDF = async () => {
    if (!user) return;
    
    const resultsElement = document.getElementById('extended-assessment-results');
    if (!resultsElement) return;
    
    setGenerating(true);
    
    try {
      // Create PDF
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      
      // Add title
      pdf.setFontSize(18);
      pdf.setTextColor(0, 51, 102); // Dark blue color
      pdf.text('Extended Assessment Results', pdfWidth / 2, 20, { align: 'center' });
      
      // Add user details
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      pdf.text(`Name: ${user.name}`, 20, 35);
      pdf.text(`Date: ${new Date().toLocaleDateString()}`, 20, 42);
      
      // Generate PDF from html content
      const canvas = await html2canvas(resultsElement, {
        scale: 1,
        useCORS: true,
        allowTaint: true,
        backgroundColor: '#ffffff'
      });
      
      const imgData = canvas.toDataURL('image/png');
      
      // Calculate ratio
      const imgWidth = pdfWidth - 40; // margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      // Add image to PDF
      pdf.addImage(imgData, 'PNG', 20, 50, imgWidth, imgHeight);
      
      // For additional pages if content is too long
      if (imgHeight > 220) {
        let heightLeft = imgHeight;
        let position = 50; // Current position
        
        // Remove first page content since we've already added it
        heightLeft -= 220;
        position -= 220;
        
        // Add new pages as needed
        while (heightLeft > 0) {
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 20, position, imgWidth, imgHeight);
          heightLeft -= 220;
          position -= 220;
        }
      }
      
      // Save the PDF
      pdf.save(`extended-assessment-${user.name.replace(/\s+/g, '_')}-${new Date().toLocaleDateString().replace(/\//g, '-')}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError('Failed to generate PDF');
    } finally {
      setGenerating(false);
    }
  };

  // Helper function to display response value properly
  const displayValue = (value: string | string[] | undefined): string => {
    if (!value) return 'Not answered';
    if (Array.isArray(value)) return value.join(', ');
    return value;
  };

  if (loading) {
    return (
      <div className="container mt-5">
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3">Loading assessment results...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
        <button
          onClick={() => window.location.reload()}
          className="btn btn-primary"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Extended Assessment Results</h1>
        <div>
          <button 
            className="btn btn-primary"
            onClick={generatePDF}
            disabled={generating}
          >
            {generating ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Generating PDF...
              </>
            ) : 'Export as PDF'}
          </button>
        </div>
      </div>
      
      <div className="card shadow-sm mb-4">
        <div className="card-body">
          <h2 className="card-title">Assessment Overview</h2>
          <p className="card-text">This report presents the results of your extended assessment for R&D tax credit eligibility.</p>
          <p className="card-text">
            <strong>Total Questions:</strong> {questions.length}<br />
            <strong>Completion Date:</strong> {new Date().toLocaleDateString()}<br />
            <strong>Assessment ID:</strong> EA-{(() => {
              if (user && typeof user._id === 'string') {
                return user._id.slice(-6);
              } else {
                return Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
              }
            })()}
          </p>
        </div>
      </div>
      
      <div id="extended-assessment-results">
        {Object.keys(sections).map(sectionKey => {
          const sectionQuestions = questions.filter(q => q.section === sectionKey);
          
          if (sectionQuestions.length === 0) return null;
          
          return (
            <div key={sectionKey} className="card shadow-sm mb-4">
              <div className="card-header bg-light">
                <h3 className="mb-0">{sections[sectionKey]}</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th style={{width: '50%'}}>Question</th>
                        <th>Your Response</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sectionQuestions.map(question => {
                        // Skip questions that depend on parent answers if the parent doesn't have the expected answer
                        if (question.parent_question_id && question.parent_answer) {
                          const parentResponse = responses[question.parent_question_id]?.value;
                          if (parentResponse !== question.parent_answer) {
                            return null;
                          }
                        }
                        
                        return (
                          <tr key={question.question_id}>
                            <td>
                              {question.text}
                              {question.guidance_text && (
                                <p className="text-muted small mt-1">{question.guidance_text}</p>
                              )}
                            </td>
                            <td>
                              {responses[question.question_id] ? (
                                <>
                                  <p>{displayValue(responses[question.question_id].value)}</p>
                                  {responses[question.question_id].file_path && (
                                    <p className="small text-muted">
                                      File uploaded: {responses[question.question_id].file_path?.split('_').pop() || 'file'}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <span className="text-muted">Not answered</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      
      <div className="d-flex justify-content-between mt-5 mb-5">
        <Link to="/extended-assessment" className="btn btn-outline-primary">
          Return to Assessment
        </Link>
        <Link to="/" className="btn btn-primary">
          Return to Home
        </Link>
      </div>
    </div>
  );
};

export default ExtendedAssessmentResults;
