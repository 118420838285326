import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  getAssessmentQuestions,
  getAssessmentResults, 
  DetailedAssessmentResult, 
  determineAssessmentOutcome,
  CertificationOutcome,
  AssessmentQuestion,
  ResponseData
} from '../services/assessmentService';
import { getApplicantInfo } from '../services/applicantService';
import ContactButton from '../components/ContactButton';
import { AuthContext } from '../context/AuthContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import '../styles/decorations.css';
import asset7 from '../assets/images/Asset 7 copy.svg';
import asset8 from '../assets/images/Asset 8 copy.svg';
import asset10 from '../assets/images/Asset 10 copy.svg';

interface LocalStorageResponse {
  questionId: string;
  questionText: string;
  value: string | string[];
}

const Results: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<DetailedAssessmentResult | null>(null);
  const [outcome, setOutcome] = useState<CertificationOutcome | null>(null);
  const [questions, setQuestions] = useState<AssessmentQuestion[]>([]);
  const [usingLocalResponses, setUsingLocalResponses] = useState(false);
  const [applicantInfo, setApplicantInfo] = useState<any>(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const resultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchResultsAndQuestions = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Get session ID from query params
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('sessionId') || localStorage.getItem('assessmentSessionId') || '';
        const assessmentId = searchParams.get('assessmentId') || localStorage.getItem('assessmentId') || '';
        
        if (!sessionId) {
          setError('No assessment session ID provided. Please complete the assessment first.');
          setLoading(false);
          return;
        }
        
        console.log(`Fetching results with sessionId=${sessionId}, assessmentId=${assessmentId}`);
        
        // Fetch both questions and results simultaneously
        const [assessmentQuestions, assessmentResults] = await Promise.all([
          getAssessmentQuestions(),
          getAssessmentResults({ sessionId, assessmentId })
        ]);
        
        setQuestions(assessmentQuestions);
        
        // Make sure the assessment results are properly typed
        if (assessmentResults) {
          setResults(assessmentResults as DetailedAssessmentResult);
        }
        
        console.log('Fetched assessment results:', assessmentResults);
        console.log('Fetched assessment questions:', assessmentQuestions);
        
      } catch (err: any) {
        console.error('Error fetching results:', err);
        setError(err.message || 'Failed to load assessment results');
        // Try using local storage as fallback
        tryLocalStorageFallback();
      } finally {
        setLoading(false);
      }
    };

    fetchResultsAndQuestions();
  }, [location.search]);
  
  useEffect(() => {
    // Fetch applicant info for PDF export
    const fetchApplicantInfo = async () => {
      try {
        const response = await getApplicantInfo();
        if (response && response.success && response.data) {
          setApplicantInfo(response.data);
        }
      } catch (error) {
        console.error('Error fetching applicant info:', error);
      }
    };

    fetchApplicantInfo();
  }, []);
  
  // Helper function to detect suspicious data patterns - hardcoded or incorrect responses
  const isDataSuspicious = (responses: ResponseData[] | undefined): boolean => {
    if (!responses || responses.length === 0) return true;
    
    // Check if responses look like they might be hardcoded/default values
    const hardcodedTextPattern = "Automated decision-making system for credit scoring";
    const suspiciousPatterns = [
      // Check for hardcoded text in any response
      responses.some(r => typeof r.value === 'string' && 
                    r.value.includes(hardcodedTextPattern)),
      
      // Check if all Yes/No questions have the same answer
      responses.filter(r => r.question_text.includes('?')).every(r => r.value === 'Yes') ||
      responses.filter(r => r.question_text.includes('?')).every(r => r.value === 'No'),
      
      // Check for hardcoded session ID pattern
      results?.session_id?.includes('test') || results?.session_id?.includes('dummy')
    ];
    
    return suspiciousPatterns.some(pattern => pattern === true);
  };
  
  // Function to get responses from local storage
  const getLocalStorageResponses = (): LocalStorageResponse[] => {
    try {
      // Try to get the final responses first (those saved at submission)
      const finalResponses = localStorage.getItem('assessmentFinalResponses');
      if (finalResponses) {
        return JSON.parse(finalResponses);
      }
      
      // Fall back to the ongoing responses
      const responses = localStorage.getItem('assessmentResponses');
      if (responses) {
        return JSON.parse(responses);
      }
    } catch (err) {
      console.error('Error retrieving responses from local storage:', err);
    }
    return [];
  };
  
  // Try to use local storage if API fails completely
  const tryLocalStorageFallback = () => {
    try {
      const localResponses = getLocalStorageResponses();
      if (localResponses && localResponses.length > 0) {
        console.log('Using local storage as complete fallback');
        setUsingLocalResponses(true);
        
        // Transform to API format
        const transformedResponses: ResponseData[] = localResponses.map(response => ({
          question_id: response.questionId,
          question_text: response.questionText,
          value: response.value
        }));
        
        // Create a minimal result object 
        const fallbackResult: DetailedAssessmentResult = {
          session_id: localStorage.getItem('assessmentSessionId') || 'local-session',
          status: 'completed',
          requires_certification: false, // Will be overridden by outcome
          certification_reasons: [],
          summary: '',
          responses: transformedResponses,
          created_at: localStorage.getItem('assessmentFinalTimestamp') || new Date().toISOString()
        };
        
        setResults(fallbackResult);
        
        // Create response map for outcome determination
        const responseMap: Record<string, string | string[]> = {};
        localResponses.forEach(response => {
          responseMap[response.questionId] = response.value;
        });
        
        // Calculate outcome
        const certificationOutcome = determineAssessmentOutcome(responseMap);
        setOutcome(certificationOutcome);
      }
    } catch (err) {
      console.error('Failed to use local storage fallback:', err);
    }
  };

  // Find the full question details based on question ID
  const getQuestionDetails = (questionId: string): AssessmentQuestion | undefined => {
    // First check top-level questions
    let question = questions.find(q => q.question_id === questionId);
    
    // If not found, search in child questions
    if (!question) {
      for (const parentQuestion of questions) {
        if (parentQuestion.children) {
          const childQuestion = parentQuestion.children.find(child => child.question_id === questionId);
          if (childQuestion) {
            return childQuestion;
          }
        }
      }
    }
    
    return question;
  };

  const handleRetakeAssessment = () => {
    navigate('/assessment');
  };

  // Function to generate and download PDF
  const generatePDF = async () => {
    if (!resultsRef.current || !results) return;
    
    setPdfLoading(true);
    try {
      // Scale factor for better resolution
      const scale = 2;
      
      // Get the container element
      const content = resultsRef.current;
      
      // Capture the content using html2canvas
      const canvas = await html2canvas(content, {
        scale: scale,
        useCORS: true,
        logging: false,
      });
      
      // Create a new PDF document
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });
      
      // Calculate dimensions
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      // Get organization details
      const orgName = applicantInfo?.organisation_name || 'Organization not specified';
      const orgAddress = applicantInfo?.address || 'Address not specified';
      
      // Get user details
      const userName = user?.name || applicantInfo?.contact_name || 'User not specified';
      const userEmail = user?.email || applicantInfo?.contact_email || 'Email not specified';
      const userRole = applicantInfo?.organisation_role || 'Role not specified';
      
      // Add title
      pdf.setFontSize(20);
      pdf.setTextColor(0, 0, 128);
      pdf.text('AI Certification Assessment Results', 105, 20, { align: 'center' });
      
      // Add date
      pdf.setFontSize(12);
      pdf.setTextColor(100, 100, 100);
      pdf.text(`Generated on: ${new Date().toLocaleDateString()}`, 105, 30, { align: 'center' });
      
      // Add organization info section
      pdf.setFontSize(16);
      pdf.setTextColor(0, 0, 0);
      pdf.text('Organization Details', 20, 45);
      
      pdf.setFontSize(12);
      pdf.text(`Name: ${orgName}`, 20, 55);
      pdf.text(`Address: ${orgAddress}`, 20, 62);
      
      // Add respondent info section
      pdf.setFontSize(16);
      pdf.text('Respondent Details', 20, 75);
      
      pdf.setFontSize(12);
      pdf.text(`Name: ${userName}`, 20, 85);
      pdf.text(`Email: ${userEmail}`, 20, 92);
      pdf.text(`Role: ${userRole}`, 20, 99);
      
      // Add assessment result summary
      pdf.setFontSize(16);
      pdf.text('Assessment Result', 20, 115);
      
      pdf.setFontSize(14);
      const requiresCertification = outcome ? outcome.requires_certification : results.requires_certification;
      if (requiresCertification) {
        pdf.setTextColor(255, 0, 0); // Red for "Certification Required"
      } else {
        pdf.setTextColor(0, 128, 0); // Green for "Certification Not Required"
      }
      pdf.text(requiresCertification ? 'Certification Required' : 'Certification Not Required', 20, 125);
      
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      
      // Add summary text
      const summary = outcome ? outcome.summary : results.summary;
      const summaryLines = pdf.splitTextToSize(summary, 170);
      pdf.text(summaryLines, 20, 135);
      
      // Add page break before the captured content
      pdf.addPage();
      
      // Add the captured content
      pdf.addImage(
        canvas.toDataURL('image/jpeg', 1.0),
        'JPEG',
        0,
        0,
        imgWidth,
        imgHeight
      );
      
      // Save the PDF
      pdf.save(`AI_Certification_Assessment_${new Date().toISOString().split('T')[0]}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setPdfLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !results) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
          {error || 'Failed to load assessment results. Please try again.'}
        </div>
        <div className="flex justify-center mt-8">
          <button
            onClick={handleRetakeAssessment}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
          >
            Retake Assessment
          </button>
        </div>
      </div>
    );
  }

  // Use the determined outcome if available, otherwise fall back to the API results
  const requiresCertification = outcome ? outcome.requires_certification : results.requires_certification;
  const certificationReasons = outcome ? outcome.certification_reasons : results.certification_reasons;
  const summary = outcome ? outcome.summary : results.summary;
  
  // Check if any responses have "NotSure" selected
  const hasNotSureResponses = results.responses && results.responses.some(
    response => response.value === 'NotSure'
  );
  
  // Check for specific text in summary or if there are "Not Sure" responses
  const showContactButton = summary.includes("clicking the Contact Button") || 
                           summary.includes("Please contact us") ||
                           hasNotSureResponses;
                           
  // Check if this is a "Not Sure" case for styling
  const isNotSure = certificationReasons && 
                   certificationReasons.some(reason => 
                     reason.includes('Not Sure') || 
                     reason.includes('Assistance needed')
                   );

  // Determine result classes based on certification requirement
  const resultClasses = requiresCertification
    ? 'bg-red-50 border-red-500 text-red-800'
    : isNotSure
    ? 'bg-yellow-50 border-yellow-500 text-yellow-800'
    : 'bg-green-50 border-green-500 text-green-800';

  // Debug check - verify responses are available and not empty
  const hasValidResponses = results.responses && results.responses.length > 0;

  return (
    <div className="max-w-5xl mx-auto relative">
      <div className="absolute -right-16 top-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset7} alt="" className="w-full h-full" />
      </div>
      <div className="absolute -left-16 bottom-40 opacity-5 w-64 h-64 hidden md:block">
        <img src={asset8} alt="" className="w-full h-full" />
      </div>
      <div className="absolute right-20 top-80 opacity-3 w-96 h-96 hidden md:block">
        <img src={asset10} alt="" className="w-full h-full" />
      </div>
      
      <div className="relative z-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Assessment Results
        </h1>
        
        {loading ? (
          <div className="flex justify-center p-12">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded mb-8">
            <p>{error}</p>
            <div className="mt-4">
              <button
                onClick={() => navigate('/assessment')}
                className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
              >
                Go Back to Assessment
              </button>
            </div>
          </div>
        ) : results ? (
          <div>
            <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
              <div className="flex justify-end mb-4">
                <button 
                  onClick={generatePDF}
                  disabled={pdfLoading}
                  className="flex items-center bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
                >
                  {pdfLoading ? (
                    <>
                      <span className="animate-spin mr-2">⟳</span>
                      Generating PDF...
                    </>
                  ) : (
                    <>
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-5 w-5 mr-2" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                        />
                      </svg>
                      Export to PDF
                    </>
                  )}
                </button>
              </div>
              
              <div ref={resultsRef}>
                <div
                  className={`border-l-4 p-6 mb-6 ${resultClasses}`}
                >
                  <h2 className="text-2xl font-bold mb-2">
                    {requiresCertification
                      ? 'Certification Required'
                      : 'Certification Not Required'}
                  </h2>
                  <p>
                    {requiresCertification
                      ? 'Based on your responses, your AI system is subject to mandatory certification under DIFC Regulation 10.'
                      : 'Based on your responses, your AI system is not subject to mandatory certification under DIFC Regulation 10.'}
                  </p>
                </div>

                <div className="mb-6 bg-white rounded-lg shadow p-6 border-l-4 border-blue-500">
                  <h2 className="text-xl font-semibold mb-4">Assessment Summary</h2>
                  <div className="flex items-start">
                    <div className={`prose ${showContactButton ? 'pr-4' : ''}`}>
                      <p>{summary}</p>
                    </div>
                    
                    {showContactButton && (
                      <div className="ml-4 flex-shrink-0">
                        <ContactButton />
                      </div>
                    )}
                  </div>
                </div>

                {certificationReasons && certificationReasons.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-3">
                      {requiresCertification
                        ? 'Reasons Certification is Required'
                        : 'Key Findings'}
                    </h3>
                    <ul className="list-disc pl-6 space-y-2 text-gray-700">
                      {certificationReasons.map((reason: string, index: number) => (
                        <li key={index}>{reason}</li>
                      ))}
                    </ul>
                  </div>
                )}

                <div className="mb-6">
                  <h3 className="text-xl font-semibold mb-3">Your Responses</h3>
                  <div className="space-y-6">
                    {hasValidResponses ? results.responses.map((response) => {
                      const questionDetails = getQuestionDetails(response.question_id);
                      
                      // Generate question number display for the results page
                      let questionNumberDisplay = response.question_id;
                      if (questionDetails && questionDetails.parent_question_id) {
                        // This is a child question, create sub-numbering
                        const parentId = questionDetails.parent_question_id;
                        // Extract the main question number (removes 'Q' prefix if it exists)
                        const mainNumber = parentId.replace(/^Q/, '');
                        
                        // Get all responses for this parent to determine lettering
                        const childQuestions = questions.filter(q => q.parent_question_id === parentId);
                        const childIndex = childQuestions.findIndex(q => q.question_id === response.question_id);
                        
                        // Create sub-letter (a, b, c, etc.) based on the child's position
                        const subLetter = String.fromCharCode(97 + (childIndex >= 0 ? childIndex : 0)); // 97 is 'a' in ASCII
                        
                        // Combine to format like "1a", "2b", etc.
                        questionNumberDisplay = `${mainNumber}${subLetter}`;
                      } else {
                        // For parent questions, just show the number without the 'Q' prefix
                        questionNumberDisplay = questionNumberDisplay.replace(/^Q/, '');
                      }
                      
                      return (
                        <div key={response.question_id} className="border-b pb-4">
                          <p className="font-medium text-gray-800 mb-2">
                            <span className="text-blue-700 mr-2">{questionNumberDisplay}:</span> 
                            {response.question_text}
                          </p>
                          
                          {questionDetails && questionDetails.guidance_text && (
                            <p className="text-gray-600 italic mb-2 text-sm">
                              {questionDetails.guidance_text}
                            </p>
                          )}
                          
                          <p className="text-gray-600">
                            <span className="font-medium">Your answer: </span>
                            {response.value !== undefined && response.value !== null ? 
                              (Array.isArray(response.value) 
                                ? response.value.join(', ') 
                                : response.value)
                              : <span className="italic text-gray-400">No response provided</span>
                            }
                          </p>
                        </div>
                      );
                    }) : (
                      <p className="text-red-600">No response data available. Please try retaking the assessment.</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <button
                  onClick={handleRetakeAssessment}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-3 px-8 rounded-lg transition duration-300"
                >
                  Retake Assessment
                </button>
                
                {requiresCertification && (
                  <button
                    onClick={() => navigate('/certification-details')}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
                  >
                    Continue to Certification Process
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-yellow-100 border border-yellow-400 text-yellow-800 px-6 py-4 rounded mb-8">
            <p>No assessment results found. Please complete the assessment.</p>
            <div className="mt-4">
              <button
                onClick={() => navigate('/assessment')}
                className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
              >
                Go to Assessment
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Results; 